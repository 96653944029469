/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
const React = require('react');
const PropTypes = require('prop-types');
const { useState, useEffect } = require('react');
const LoadingSpinner = require('../LoadingSpinner');
const { userSearchHistory } = require('../../utils/search-client');
const UserHistory = require('./UserHistory');
const TabsHistory = require('./TabsHistory');
const CommunityHistory = require('./CommunityHistory');

const SearchHistory = ({ i18n, isMobile, onClickUserHistoryItem }) => {
  const [history, setHistory] = useState({});
  const [loading, setLoading] = useState(true);
  const hasUserHistory = () => Boolean(history?.user?.length);
  const hasCommunityHistory = () => Boolean(history?.community?.length);

  useEffect(() => {
    setHistory({
      community: [],
      user: userSearchHistory(),
    });

    setLoading(false);
  }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {hasUserHistory() && !hasCommunityHistory() && (
        <UserHistory
          title={i18n.gettext('Búsquedas Recientes')}
          isMobile={isMobile}
          items={history?.user}
          onClickItem={onClickUserHistoryItem}
          i18n={i18n}
        />
      )}

      {hasCommunityHistory() && !hasUserHistory() && (
        <CommunityHistory
          title={i18n.gettext('Más buscadas')}
          isMobile={isMobile}
          items={history?.community}
        />
      )}

      {hasUserHistory() && hasCommunityHistory() && (
        <TabsHistory
          i18n={i18n}
          history={history}
          isMobile={isMobile}
          onClickUserHistoryItem={onClickUserHistoryItem}
        />
      )}
    </>
  );
};

SearchHistory.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }),
  isMobile: PropTypes.bool.isRequired,
};

module.exports = SearchHistory;
