/**
 * Somethings itens from de classic search API can be duplicated, or with differente
 * endpoints but pointing to the exact same document view (doc). This function deduplicates it
 */
function dedupByDocPaths(arr) {
  const filtered = [];

  arr.forEach(item => {
    try {
      if (item && typeof item === 'object') {
        const docPath = item?.docPath;
        const seenItem = docPath && filtered.find(x => x.docPath === docPath);

        if (!docPath || !seenItem) {
          filtered.push(item);
        }
      }
    } catch (error) {
      console.error(error);
    }
  });

  return filtered;
}

/**
 * The classic search has a bug which is returning duplicated items.
 * This may take a while to be solved by the backend. As a temporary fix
 * we will deduplicate the items on the client.
 */

const dedupClassicSearchItems = (arr, dedupDocPaths = true) => {
  const preFilter = Array.from(new Set(arr.map(item => JSON.stringify(item)))).map(item => JSON.parse(item));
  return dedupDocPaths ? dedupByDocPaths(preFilter) : preFilter;
};

module.exports = {
  dedupClassicSearchItems,
};
