/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');
const injectI18n = require('nordic/i18n/injectI18n');
const Home = require('../../components/Home');


const View = (props) => <Home {...props} />;

View.propTypes = {
  platform: PropTypes.shape({
    siteId: PropTypes.string.isRequired,
  }).isRequired,
  sentDate: PropTypes.string.isRequired,
  assetsPath: PropTypes.string.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
  lang: PropTypes.string.isRequired,
};

/**
 * Inject i18n context as props into View.
 */
module.exports = injectI18n(View);
