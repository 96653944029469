/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
const React = require('react');
const PropTypes = require('prop-types');
const LoadingSpinner = require('../LoadingSpinner');
const EmptyResult = require('../EmptyResult');
const ResultItem = require('../ResultItem');
const ResultsAI = require('../ResultsAI');
const { Pill } = require('@andes/badge');

let itemId = 0;

const SearchResults = ({
  results,
  showResults,
  i18n,
  term,
  lang,
  siteId,
  loading,
  empty,
  hasAIAnswer,
  isMobile,
  onClickSuggestion,
}) => {
  return (
    <>
      {loading && <LoadingSpinner />}

      {!loading && showResults && (
        <>
          <p className="result-box-header">
            {hasAIAnswer ? i18n.gettext('BÚSQUEDA INTELIGENTE') : i18n.gettext('Resultado de búsqueda')}

            {hasAIAnswer && (
              <span style={{ marginLeft: 8 }}>
                <Pill
                  color="accent"
                  hierarchy="quiet"
                  size="small"
                  srLabel="BETA"
                  roundedCorners={[
                    'top-left',
                    'top-right',
                    'bottom-left',
                    'bottom-right',
                  ]}
                >
                  {i18n.gettext('BETA')}
                </Pill>
              </span>
            )}
          </p>

          {empty && <EmptyResult i18n={i18n} />}

          {!empty && (!hasAIAnswer
            ? (results?.classic?.map(item => {
              itemId += 1;

              return (
                <ResultItem
                  isMobile={isMobile}
                  siteId={siteId}
                  result={item}
                  key={itemId}
                  lang={lang}
                  i18n={i18n}
                />
              );
            })) : (
              <ResultsAI
                term={term}
                lang={lang}
                siteId={siteId}
                results={results}
                onClickSuggestion={onClickSuggestion}
            />))}
        </>
      )}
    </>
  );
};

SearchResults.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }),
  term: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
};

module.exports = SearchResults;
