const restclient = require('nordic/restclient');
const { DxVirtualAssistent } = require('dx-virtual-assistent-sdk');
const { parseResultItem } = require('./parse-result-item');
const { dedupClassicSearchItems } = require('./dedup-classic-search');

const httpClient = restclient({ timeout: 30000, baseURL: '' }); // TO DO: Voltar para 10s para produçao
const SearchAPI = {
  classic: {
    search(query) {
      const { words, lang, site } = query;
      const payload = { params: { term: words } };

      const request = (url, delay) => {
        return new Promise(resolve => {
          setTimeout(() => {
            resolve(httpClient.get(url, payload));
          }, delay || 0);
        });
      };

      return Promise.allSettled([
        request(`/developers/api/search/references/${lang}`),
        request(`/developers/api/search/guides/${lang}`, 300),
      ])
        .then(data => {
          const results = [
            ...(data?.[0]?.value?.data || []),
            ...(data?.[1]?.value?.data || []),
          ]
            .map(item => parseResultItem(item, lang, site))
            .filter(item => item);

          return dedupClassicSearchItems(results);
        });
    },
  },

  llm: {
    ask(query) {
      const params = new URLSearchParams(query).toString();
      return httpClient.get(`/developers/api/search-llm/ask?${params}`);
    },

    feedback(data) {
      const params = new URLSearchParams(data).toString();
      return httpClient.get(`/developers/api/search-llm/feedback?${params}`);
    },
  },

  assistent(options = {}) {
    const httpClients = {
      classic: (query, lang) => SearchAPI.classic.search(query, lang),
      feedback: data => SearchAPI.llm.feedback(data).then(result => result.data),
      llm: (query) => {
        const isAIEnabled = SearchAPI.isAIEnabled(options.site);

        return (
          isAIEnabled
            ? SearchAPI.llm.ask(query).then(result => {
              return result.data;
            })
            : SearchAPI.requestNOOP()
        );
      },
    };

    if (options.noClassicSearch) {
      httpClients.classic = () => Promise.resolve({});
    }

    return new DxVirtualAssistent({
      ...options,
      httpClients,
      highlightPreCode: true,
    });
  },

  userSearchHistory() {
    return SearchAPI.assistent().searchHistory();
  },

  isAIEnabled(/* site */) {
    // if want to allow only specific site id's
    // return ['MLC', 'MLU', 'MPE', 'MCO', 'MLM'].includes(site);
    return true;
  },


  requestNOOP() {
    return Promise.resolve({});
  },
};

module.exports = SearchAPI;
