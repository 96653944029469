/* eslint-disable react/prop-types */
const React = require('react');
const UserHistory = require('../UserHistory');
const { Tabs, Tab, TabContent } = require('@andes/tabs');
const CommunityHistory = require('../CommunityHistory');

const TabsHistory = ({ i18n, history, isMobile, onClickUserHistoryItem }) => (
  <Tabs className="tabs" id="tabs-id" srLabel="Custom handler label">
    <Tab title={i18n.gettext('Búsquedas Recientes')}>
      <TabContent>
        <UserHistory
          isMobile={isMobile}
          items={history?.user}
          onClickItem={onClickUserHistoryItem}
        />
      </TabContent>
    </Tab>

    <Tab title={i18n.gettext('Más buscadas')}>
      <TabContent>
        <CommunityHistory
          isMobile={isMobile}
          items={history?.user}
        />
      </TabContent>
    </Tab>
  </Tabs>
);

module.exports = TabsHistory;
