const React = require('react');

const SearchIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    fill="none"
    viewBox="0 0 15 15"
    className="searchbar-icon-search"
  >
    <path
      fill="#000"
      fillOpacity="0.55"
      fillRule="evenodd"
      d="M9.997 10.846a6 6 0 11.849-.848l4.002 4-.849.85-4.002-4.002zM1.401 6.2a4.8 4.8 0 109.6 0 4.8 4.8 0 00-9.6 0z"
      clipRule="evenodd"
    />
  </svg>
);

module.exports = SearchIcon;
