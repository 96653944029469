/* eslint-disable */
const React = require('react');

module.exports = function DebugDetails() {
  if (process.env.NODE_ENV !== 'development') {
    return null;
  }

  return (
    <div style={{fontSize: 12}}>
      <p><strong>DEBUG HELP: This is visible only in Development Mode. Keywords to Front End test AI Search Bar:</strong></p>
      <hr/>
      <p><strong>__test:*</strong> If you start a question with __test:, the lib will simulate a successful answer and will not send a real request to the LLM-AI.</p>
      <p><strong>__test:error</strong> If you start a question with __test:error, the lib will simulate an error and will not send a real request to the LLM-AI.</p>
      <p><strong>__test:timeout</strong> If you start a question with __test:timeout, the lib will simulate a timeout will not send a real request to the LLM-AI.</p>
      <p><strong>__test:empty</strong> If you start a question with __test:empty, the lib will simulate a timeout will not send a real request to the LLM-AI.</p>
      <p><strong>__test:ambiguous</strong> If you start a question with __test:ambiguous, the lib will simulate an ambiguous flag answer from the API and will not send a real request to the LLM-AI.</p>
      <p><strong>__test:md</strong> Enter markdown debug mode, very useful to debug markdown and rendering capabilities.</p>
      <p><strong>__suggestions!</strong> Add this key to any part of a question to force suggestions generations for the keywords on this question</p>
      <p><strong>__query!</strong> Add this key to any part of a question to receive on the response the Query payload generated by the BFF that was sent to the AI API</p>
    </div>
  );
}
/* eslint-enable */
