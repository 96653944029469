const React = require('react');

function NotebookImage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 100 100"
    >
      <path
        fill="#5AD2FF"
        stroke="#fff"
        strokeWidth="3"
        d="M11.56 29.161H87.331V71.373H11.56z"
      />
      <path
        stroke="#333"
        strokeWidth="1.5"
        d="M10.224 27.554H88.668V72.655H10.224z"
      />
      <path
        fill="#fff"
        stroke="#333"
        strokeWidth="1.5"
        d="M10.224 25.913a2.797 2.797 0 012.796-2.797h72.865a2.797 2.797 0 012.797 2.797v1.79H10.224v-1.79z"
      />
      <path
        fill="#fff"
        d="M3.964 72.739a5.004 5.004 0 005.004 5.004h80.97a5.004 5.004 0 005.004-5.004H3.964z"
      />
      <path
        fill="#EEE"
        fillRule="evenodd"
        d="M9.174 76.33h80.56c2.23 0 4.118-1.202 4.744-2.855h.22l-.001.112c-.073 2.186-2.267 3.94-4.964 3.94H9.174c-2.742 0-4.965-1.814-4.965-4.052h.22c.627 1.653 2.514 2.854 4.745 2.854z"
        clipRule="evenodd"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path
        stroke="#333"
        strokeWidth="1.5"
        d="M3.964 72.739a5.004 5.004 0 005.004 5.004h80.97a5.004 5.004 0 005.004-5.004H3.964z"
      />
      <path
        fillRule="evenodd"
        stroke="#333"
        strokeWidth="1.5"
        d="M40.838 72.739c0 .921.747 1.668 1.669 1.668h13.89c.922 0 1.67-.747 1.67-1.668h-17.23z"
        clipRule="evenodd"
      />
      <ellipse
        cx="49.678"
        cy="25.626"
        fill="#333"
        rx="0.7"
        ry="0.699"
      />
      <path
        fill="#fff"
        stroke="#333"
        strokeWidth="1.5"
        d="M33.266 34.694H66.295V64.884H33.266z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M66.709 45.173H32.477c-1.24.002-2.246 1.145-2.248 2.556v4.35c.002 1.413 1.007 2.558 2.248 2.56h34.232c1.24-.002 2.246-1.147 2.248-2.56v-4.35c-.003-1.411-1.008-2.554-2.248-2.556zM30.229 37.705v2.205c.002 1.412 1.007 2.557 2.248 2.56h34.232c1.24-.003 2.246-1.148 2.248-2.56v-4.35c-.002-1.413-1.007-2.558-2.248-2.56H32.477c-1.241.002-2.246 1.147-2.248 2.56v2.145zM66.709 57.434H32.477c-1.24.002-2.246 1.145-2.248 2.556v4.35c.002 1.413 1.007 2.558 2.248 2.56h34.232c1.24-.002 2.246-1.147 2.248-2.56v-4.35c-.003-1.411-1.008-2.554-2.248-2.556z"
        clipRule="evenodd"
      />
      <path
        fill="#EEE"
        fillRule="evenodd"
        d="M30.229 39.818v-1.595c.002 1.412 1.007 2.557 2.248 2.56h34.232c1.24-.003 2.246-1.148 2.248-2.56v1.595c-.002 1.413-1.007 2.558-2.248 2.56H32.477c-1.241-.002-2.246-1.147-2.248-2.56zm0 10.666v1.596c.002 1.412 1.007 2.557 2.248 2.559h34.232c1.24-.002 2.246-1.147 2.248-2.56v-1.595c-.002 1.412-1.007 2.557-2.248 2.56H32.477c-1.241-.003-2.246-1.148-2.248-2.56zm0 12.26v1.597c.002 1.412 1.007 2.557 2.248 2.56h34.232c1.24-.003 2.246-1.148 2.248-2.56v-1.596c-.002 1.412-1.007 2.557-2.248 2.56H32.477c-1.241-.003-2.246-1.148-2.248-2.56z"
        clipRule="evenodd"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path
        fillRule="evenodd"
        stroke="#333"
        strokeWidth="1.5"
        d="M66.709 45.173H32.477c-1.24.002-2.246 1.145-2.248 2.556v4.35c.002 1.413 1.007 2.558 2.248 2.56h34.232c1.24-.002 2.246-1.147 2.248-2.56v-4.35c-.003-1.411-1.008-2.554-2.248-2.556zM30.229 37.705v2.205c.002 1.412 1.007 2.557 2.248 2.56h34.232c1.24-.003 2.246-1.148 2.248-2.56v-4.35c-.002-1.413-1.007-2.558-2.248-2.56H32.477c-1.241.002-2.246 1.147-2.248 2.56v2.145zM66.709 57.434H32.477c-1.24.002-2.246 1.145-2.248 2.556v4.35c.002 1.413 1.007 2.558 2.248 2.56h34.232c1.24-.002 2.246-1.147 2.248-2.56v-4.35c-.003-1.411-1.008-2.554-2.248-2.556z"
        clipRule="evenodd"
      />
      <mask id="path-17-inside-1_1038_165829" fill="#fff">
        <path
          fillRule="evenodd"
          d="M39.64 37.564a2.467 2.467 0 01-2.47 2.464 2.467 2.467 0 01-2.47-2.464 2.467 2.467 0 012.47-2.464 2.467 2.467 0 012.47 2.464zm0 12.342a2.467 2.467 0 01-2.47 2.464 2.467 2.467 0 01-2.47-2.464 2.467 2.467 0 012.47-2.464 2.467 2.467 0 012.47 2.464zm-2.47 14.637a2.467 2.467 0 002.47-2.464 2.467 2.467 0 00-2.47-2.464 2.467 2.467 0 00-2.47 2.464 2.467 2.467 0 002.47 2.464z"
          clipRule="evenodd"
        />
      </mask>
      <path
        fill="#5AD2FF"
        fillRule="evenodd"
        d="M39.64 37.564a2.467 2.467 0 01-2.47 2.464 2.467 2.467 0 01-2.47-2.464 2.467 2.467 0 012.47-2.464 2.467 2.467 0 012.47 2.464zm0 12.342a2.467 2.467 0 01-2.47 2.464 2.467 2.467 0 01-2.47-2.464 2.467 2.467 0 012.47-2.464 2.467 2.467 0 012.47 2.464zm-2.47 14.637a2.467 2.467 0 002.47-2.464 2.467 2.467 0 00-2.47-2.464 2.467 2.467 0 00-2.47 2.464 2.467 2.467 0 002.47 2.464z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        d="M37.17 43.028a5.467 5.467 0 005.47-5.464h-6c0-.304.245-.536.53-.536v6zm-5.47-5.464a5.467 5.467 0 005.47 5.464v-6c.285 0 .53.232.53.536h-6zm5.47-5.464a5.467 5.467 0 00-5.47 5.464h6a.533.533 0 01-.53.536v-6zm5.47 5.464a5.467 5.467 0 00-5.47-5.464v6a.533.533 0 01-.53-.536h6zM37.17 55.37a5.467 5.467 0 005.47-5.464h-6c0-.304.245-.536.53-.536v6zm-5.47-5.464a5.467 5.467 0 005.47 5.464v-6c.285 0 .53.232.53.536h-6zm5.47-5.464a5.467 5.467 0 00-5.47 5.464h6a.533.533 0 01-.53.536v-6zm5.47 5.464a5.467 5.467 0 00-5.47-5.464v6a.533.533 0 01-.53-.536h6zm-6 12.173c0-.303.245-.536.53-.536v6a5.467 5.467 0 005.47-5.464h-6zm.53.536a.533.533 0 01-.53-.536h6a5.467 5.467 0 00-5.47-5.464v6zm.53-.536a.533.533 0 01-.53.536v-6a5.467 5.467 0 00-5.47 5.464h6zm-.53-.536c.285 0 .53.233.53.536h-6a5.467 5.467 0 005.47 5.464v-6z"
        mask="url(#path-17-inside-1_1038_165829)"
      />
      <ellipse
        cx="37.17"
        cy="37.564"
        stroke="#333"
        strokeWidth="1.5"
        rx="2.47"
        ry="2.464"
      />
      <ellipse
        cx="37.17"
        cy="49.906"
        stroke="#333"
        strokeWidth="1.5"
        rx="2.47"
        ry="2.464"
      />
      <ellipse
        cx="37.17"
        cy="62.079"
        stroke="#333"
        strokeWidth="1.5"
        rx="2.47"
        ry="2.464"
      />
      <path
        stroke="#333"
        strokeWidth="1.5"
        d="M46.99 37.735h18.044M46.99 49.906h18.044M46.99 62.08h18.044M45.203 37.735H41.84M45.203 49.906H41.84M45.203 62.08H41.84"
      />
    </svg>
  );
}

module.exports = NotebookImage;
