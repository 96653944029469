/* eslint-disable max-len */
const React = require('react');

const CdpCardHelp = () => (
  <svg className="home-help--image" width="52" height="38" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M49.3191 26C49.3191 38.7011 38.8554 49.037 25.8985 49.037C12.9416 49.037 2.47778 38.7011 2.47778 26C2.47778 13.2988 12.9416 2.96295 25.8985 2.96295C38.8554 2.96295 49.3191 13.2988 49.3191 26Z" fill="#5AD2FF" stroke="white" strokeWidth="3" />
    <ellipse cx="25.7217" cy="26" rx="24.9207" ry="24.537" stroke="#333333" strokeWidth="1.5" />
    <path fillRule="evenodd" clipRule="evenodd" d="M37.5067 18.5387L34.6699 18.5471L28.9733 12.8054L17.0867 18.5485H13.2876V16.3429H8.88599V18.542H11.0853V35.0285H8.88599V37.2276H13.2876V32.8295H17.2782L21.3602 36.9056C22.8909 38.434 25.3058 38.5414 26.9607 37.2276H29.0641C31.2945 37.2276 33.3046 35.8839 34.1544 33.8248L35.0205 31.7263H37.5067V37.2276H41.9201V35.0285H39.709V18.542H41.9201V16.3429H37.5067V18.5387ZM28.1034 20.4024L34.7717 29.5272H37.5067V20.7377L33.7544 20.749L28.5162 15.4693L18.872 20.1289C19.5955 21.0395 20.7664 21.5207 21.9582 21.339L28.1034 20.4024ZM33.0045 30.8396L27.1131 22.7779L22.2905 23.5129C19.999 23.8621 17.7541 22.7413 16.6465 20.7476H13.2876V30.6304H15.8642C15.487 29.2306 15.8485 27.6737 16.9487 26.5751C18.6061 24.9306 21.2675 24.9327 22.9128 26.5704L27.3173 30.9547C28.4247 32.057 28.7894 33.6217 28.4105 35.0285H29.0641C30.4023 35.0285 31.6084 34.2223 32.1183 32.9868L33.0045 30.8396ZM25.7648 35.3506C24.9785 36.1357 23.7037 36.1357 22.9175 35.3506L18.506 30.9455C17.7274 30.168 17.7274 28.9075 18.5105 28.1256C19.2985 27.3437 20.5712 27.3447 21.358 28.1279L25.7625 32.5121C26.5481 33.2941 26.5501 34.5639 25.7648 35.3506Z" fill="#333333" stroke="white" />
  </svg>

);

module.exports = CdpCardHelp;
