function product(urlStr) {
  const url = new URL(urlStr, 'https://test.com');

  const productArray = url
    .pathname
    .split('/')
    .filter(item => {
      const prohibited = [
        'pt',
        'es',
        'en',
        'docs',
        'guides',
        'reference',
        'developers',
      ];

      return item && !prohibited.includes(item);
    });

  return productArray[0] || '';
}


function noTrailingSlash(str) {
  if (!str || typeof str !== 'string') {
    return '';
  }

  return str.replace(/\/$/, '');
}

function isSamePathname(a, b) {
  const pathname = n => noTrailingSlash(new URL(n, 'https://test.com')?.pathname);

  try {
    return pathname(a) === pathname(b);
  } catch (error) {
    console.error(error);
    return false;
  }
}

function mergeLinksAndSources(list, sources, max = 10) {
  try {
    if (!list?.length && !sources?.length) {
      return [];
    }

    if (!list || !Array.isArray(list)) {
      return mergeLinksAndSources([], sources, max);
    }

    sources.forEach(sourceItem => {
      const duplicated = list?.length && list.some(listItem => {
        const a = noTrailingSlash(listItem?.endpoint)?.toLowerCase();
        const b = noTrailingSlash(sourceItem?.source)?.toLowerCase();

        if (a === b) {
          return true;
        }

        if (isSamePathname(a, b) || isSamePathname(b, a)) {
          return true;
        }

        return false;
      });

      if (!duplicated) {
        list.unshift({
          title: sourceItem.title,
          endpoint: sourceItem.source,
          section: sourceItem.section,
        });
      }
    });

    return list.reduce((result, item) => {
      const hasSameSubject = result.some(check => (check.title?.trim() === item.title?.trim()) && (check.section === item.section));
      const hasSameContent = item.content && result.some(check => check.content === item.content);

      if (hasSameSubject) {
        item.section = product(item.endpoint);

        if (!item.section || result.some(check => (check.title === item.title) && (check.section === item.section))) {
          return result;
        }
      }

      const isSameLink = result.some(check => {
        return isSamePathname(check.endpoint, item.endpoint) || isSamePathname(item.endpoint, check.endpoint);
      });

      if (!hasSameContent && !isSameLink) {
        result.push(item);
      }

      return result;
    }, []).slice(0, max);
  } catch (error) {
    // the try catch is here for resilience purposes only, for ex:
    // an exception in the api that delivers null as an argument.
    // in ideal conditions this must not pass, but if happen we
    // return an empty array to avoid a render uncauth error
    console.error(error);
    return [];
  }
}

module.exports = {
  mergeLinksAndSources,
};
