const React = require('react');

function ShieldImage() {
  return (
    <svg width="66" style={{ width: '66px' }} height="80" viewBox="0 0 66 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M2.06685 12.4919C1.88292 12.5598 1.73285 12.6786 1.62808 12.8284C1.5232 12.9783 1.46369 13.1592 1.46427 13.3501L1.46428 38.9441C1.42192 48.365 4.6409 57.1893 10.1827 64.2253C15.7234 71.2598 23.5858 76.5069 33.2719 78.7752C42.5158 76.5069 50.3783 71.2598 55.9189 64.2253C61.4607 57.1893 64.6797 48.365 64.6374 38.9441V13.3587C64.6379 13.1592 64.5784 12.9783 64.4736 12.8284C64.3688 12.6786 64.2187 12.5598 64.0381 12.4931L33.4039 1.13592C33.1747 1.06016 32.9269 1.06016 32.6978 1.13592L2.06685 12.4919Z" fill="white" />
      <path d="M64.5035 38.9429V13.3274V38.9429ZM64.5035 38.9429C64.5457 48.3749 61.3404 57.2096 55.822 64.2539M64.5035 38.9429L55.822 64.2539M55.822 64.2539C50.3049 71.2967 42.4757 76.55 33.271 78.8209L55.822 64.2539ZM3.09807 38.9496V38.9429L3.09805 13.7249L33.0508 2.56002L63.0035 13.7249L63.0035 38.9429L63.0036 38.9496C63.0442 48.0335 59.9579 56.542 54.6412 63.3289C49.3958 70.0248 41.9816 75.0419 33.2626 77.2763C24.1168 75.0411 16.7039 70.0223 11.4604 63.3289C6.14373 56.542 3.05743 48.0335 3.09807 38.9496ZM63.0035 13.323V13.3274C63.0035 13.3259 63.0035 13.3244 63.0035 13.323Z" fill="#5AD2FF" stroke="white" strokeWidth="3" />
      <path fillRule="evenodd" clipRule="evenodd" d="M2.08578 12.4596C1.90263 12.5276 1.7532 12.6465 1.64887 12.7965C1.54444 12.9466 1.48518 13.1277 1.48575 13.3188L1.48576 38.9429C1.44359 48.3749 4.64892 57.2096 10.1673 64.2539C15.6844 71.2967 23.5136 76.55 33.1587 78.8209C42.3634 76.55 50.1926 71.2967 55.7097 64.2539C61.2281 57.2096 64.4334 48.3749 64.3912 38.9429V13.3274C64.3918 13.1277 64.3326 12.9466 64.2281 12.7965C64.1238 12.6465 63.9744 12.5276 63.7945 12.4608L33.29 1.09024C33.0619 1.01439 32.8151 1.01439 32.587 1.09024L2.08578 12.4596Z" stroke="#333333" strokeWidth="1.5" />
      <ellipse cx="32.9382" cy="32.9583" rx="16.2712" ry="16.25" fill="white" stroke="#333333" strokeWidth="1.5" />
    </svg>

  );
}

module.exports = ShieldImage;
