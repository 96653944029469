/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-target-blank */
const React = require('react');
const CarouselSnapped = require('@andes/carousel-snapped');

const { CarouselSnappedSlide } = CarouselSnapped;
const { getEventTracker } = require('../../../../../utils/event-tracker');

const HomeDevProgramCarouselMobile = ({ children, className }) => (
  <div className="home-dev-program__carousel-mobile">
    <CarouselSnapped className={className} arrows={false} scroll="visible">
      {children}
    </CarouselSnapped>
  </div>
);
HomeDevProgramCarouselMobile.CarouselItemMobile = ({
  title,
  index,
  subtitle,
  url,
  image,
  linkDescription,
  className,
  tracking,
  label,
}) => {
  const Tracker = getEventTracker();

  return (
    <CarouselSnappedSlide
      className={className}
      index={index}
      key={`carousel__slide ${index}`}
    >
      <a href={url} target="_self">
        <div className="wrapper">
          {image}
          <h1 className="title">{title}</h1>
          <p className="subtitle">{subtitle}</p>
          <a
            href={url}
            target="_self"
            title={label}
            onClick={() => { Tracker.send(`mpdevelopers/click_${tracking}`); }}
          >
            {linkDescription}
          </a>
        </div>
      </a>
    </CarouselSnappedSlide>
  );
};

module.exports = HomeDevProgramCarouselMobile;
