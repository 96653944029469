const React = require('react');
const PropTypes = require('prop-types');
const Content = require('./Content');
const Feedback = require('./Feedback');
const Footer = require('./Footer');
const RelatedLinks = require('./RelatedLinks');
const Suggestions = require('./Suggestions');

const SearchAI = ({ term, siteId, results, lang, onClickSuggestion }) => {
  const [showAfterContent, setShowAfterContent] = React.useState(false);
  const showRelatedLinks = !results?.llm?.bot_not_respond;

  return (
    <div className="search-result-ai" tabIndex={0}>
      <Content
        term={term}
        lang={lang}
        siteId={siteId}
        results={results}
        onProgressChange={n => setShowAfterContent(n >= 90)}
      />

      {showAfterContent && (
        <>
          {showRelatedLinks && (
            <RelatedLinks
              list={results?.classic || []}
              sources={results?.llm?.sources || []}
            />
          )}

          <Feedback
            lang={lang}
            id={results?.llm?.id}
            siteId={siteId}
            answer={results.llm.answer}
          />

          <Footer>
            <Suggestions
              max={5}
              onClickSuggestion={onClickSuggestion}
              items={results?.llm?.suggestions || []}
            />
          </Footer>
        </>
      )}
    </div>
  );
};

SearchAI.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
  results: PropTypes.shape({
    classic: PropTypes.arrayOf({
      content: PropTypes.string.isRequired,
      docPath: PropTypes.string.isRequired,
      highlight: PropTypes.string.isRequired,
      score: PropTypes.number.isRequired,
      section: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }).isRequired,
    llm: PropTypes.shape({
      answer: PropTypes.string.isRequired,
      html: PropTypes.string.isRequired,
      id: PropTypes.string.isRequired,
    }).isRequired,
    mock: PropTypes.bool.isRequired,
    query: PropTypes.shape({
      date_created: PropTypes.instanceOf(Date).isRequired,
      product: PropTypes.string.isRequired,
      site: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

module.exports = SearchAI;
