/* eslint-disable react/prop-types */
const React = require('react');
const { getEventTracker } = require('../../../../../utils/event-tracker');

const ProgrammingLanguage = ({ image, language, link, tracking, label }) => {
  const Tracker = getEventTracker();

  return (
    <div className="home-programming-language">
      <a href={link} rel="noreferrer" onClick={() => { Tracker.send(`mpdevelopers/click_${tracking}_image`); }}>{image}</a>
      <a href={link} rel="noreferrer" title={label} onClick={() => { Tracker.send(`mpdevelopers/click_${tracking}_link`); }}>{language}</a>
    </div>
  );
};

module.exports = ProgrammingLanguage;

