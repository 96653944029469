/* eslint-disable jsx-a11y/control-has-associated-label */
const React = require('react');
const PropTypes = require('prop-types');
const SearchIcon = require('../SearchIconSvg');
const SendIcon = require('../SendIconSvg');
const { useDebounce } = require('../../utils/debounce');
const { isAIEnabled } = require('../../utils/search-client');

const { useState, useEffect } = React;

const SearchInput = ({
  i18n,
  siteId,
  lang,
  onTyped,
  onFocus,
  layout,
  onBlur,
  isActive,
  onSubmit,
  placeholder,
  allowSearching,
}) => {
  const [searchText, setSearchText] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const debounceDelay = isAIEnabled(siteId) ? 200 : 600;
  const term = useDebounce(searchText, debounceDelay);

  useEffect(() => {
    const phrase = term?.trim() || '';

    if (onTyped) {
      onTyped(phrase);
    }

    setIsButtonDisabled(!allowSearching);
  }, [term, allowSearching]);

  const submit = e => {
    e.preventDefault();
    const phrase = term?.trim() || '';

    if (onSubmit) {
      onSubmit(phrase);
    }
  };

  const getPlaceholder = () => {
    if (placeholder) {
      return placeholder;
    }

    if (layout === 'sidebar') {
      return (
        isAIEnabled(siteId)
          ? i18n.gettext('Hace una pregunta')
          : i18n.gettext('Buscar')
      );
    }

    return (
      isAIEnabled(siteId)
        ? i18n.gettext('Hace una pregunta o busca en la documentación')
        : i18n.gettext('Buscar una documentación')
    );
  };

  return (
    <>
      <div className="search-input-container">
        <SearchIcon />

        <form onSubmit={submit}>
          <input
            value={searchText}
            name="ai-search-input"
            autoComplete="off"
            tabIndex={0}
            placeholder={getPlaceholder()}
            onInput={({ target }) => setSearchText(target.value)}
            onBlur={e => {
              if (onBlur) {
                onBlur(e);
              }
            }}
            onFocus={e => {
              if (onFocus) {
                onFocus(e);
              }
            }}
          />

          <button
            disabled={isButtonDisabled}
            className="searchbar-button-send"
            hidden={!isAIEnabled(siteId) || !isActive}
          >
            <SendIcon />
          </button>
        </form>
      </div>

      <div className="tip-container">
        <p>
          {i18n.gettext('¿No sabes cómo empezar la integración?')}&nbsp;
        </p>

        <a href={`/developers/${lang}/docs/getting-started`}>
          {i18n.gettext('Accede a los primeros pasos')}
        </a>
      </div>
    </>
  );
};

SearchInput.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }),
  lang: PropTypes.shape({}).isRequired,
  onTyped: PropTypes.func.isRequired,
  onFocus: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
};

module.exports = SearchInput;
