/* eslint-disable max-len */
const React = require('react');

const HomePhytonImage = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M40.0001 17.5442C38.1845 17.5528 36.4506 17.7095 34.9249 17.9828C30.4305 18.7867 29.6145 20.4691 29.6145 23.5719V27.6698H40.2353V29.0357H29.6145H25.6287C22.542 29.0357 19.8392 30.914 18.9938 34.487C18.0186 38.5825 17.9754 41.1382 18.9938 45.4146C19.7487 48.5978 21.5517 50.8658 24.6384 50.8658H28.29V45.9534C28.29 42.4045 31.3231 39.2741 34.9249 39.2741H45.5333C48.4863 39.2741 50.8437 36.8126 50.8437 33.8103V23.5719C50.8437 20.658 48.4155 18.4691 45.5333 17.9828C43.7088 17.6754 41.8157 17.5357 40.0001 17.5442ZM34.2565 20.84C35.3535 20.84 36.2494 21.7618 36.2494 22.8952C36.2494 24.0246 35.3535 24.9379 34.2565 24.9379C33.1555 24.9379 32.2635 24.0246 32.2635 22.8952C32.2635 21.7618 33.1555 20.84 34.2565 20.84Z" fill="url(#paint0_linear_986_172144)" />
    <path d="M52.3447 29.1342V33.8724C52.3447 37.5459 49.2511 40.6377 45.7233 40.6377H35.1365C32.2366 40.6377 29.8369 43.1363 29.8369 46.0599V56.2204C29.8369 59.1121 32.3347 60.813 35.1365 61.6426C38.4916 62.6357 41.709 62.8152 45.7233 61.6426C48.3917 60.8648 51.0229 59.2995 51.0229 56.2204V52.1537H40.4361V50.7981H51.0229H56.3225C59.4029 50.7981 60.5508 48.6351 61.6221 45.3884C62.7287 42.0459 62.6816 38.8317 61.6221 34.5439C60.8608 31.4568 59.4069 29.1342 56.3225 29.1342H52.3447ZM46.3904 54.8648C47.4892 54.8648 48.3793 55.7711 48.3793 56.8919C48.3793 58.0167 47.4892 58.9315 46.3904 58.9315C45.2956 58.9315 44.4015 58.0167 44.4015 56.8919C44.4015 55.7711 45.2956 54.8648 46.3904 54.8648Z" fill="url(#paint1_linear_986_172144)" />
    <defs>
      <linearGradient id="paint0_linear_986_172144" x1="18.2461" y1="17.5439" x2="42.9892" y2="38.3708" gradientUnits="userSpaceOnUse">
        <stop stopColor="#5A9FD4" />
        <stop offset="1" stopColor="#306998" />
      </linearGradient>
      <linearGradient id="paint1_linear_986_172144" x1="46.1357" y1="56.55" x2="37.2895" y2="44.2364" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FFD43B" />
        <stop offset="1" stopColor="#FFE873" />
      </linearGradient>
    </defs>
  </svg>

);

module.exports = HomePhytonImage;
