const { getEventTracker } = require('../../../../../../../utils/event-tracker');

const builderLinkPartner = {
  MLA: 'ar',
  MLB: 'br',
  MLM: 'mx',
};

export const trackingVisibilityCDP = (pathGA) => {
  const Tracker = getEventTracker();

  Tracker.send(`ACCESS_POINT_CDP/${pathGA}`);
};

export const changeDomainUrl = (siteId) => {
  const base = siteId !== 'MLB' ? `https://centrodepartners.mercadolibre.com.${builderLinkPartner[siteId]}` : `https://centrodepartners.mercadolivre.com.${builderLinkPartner[siteId]}`;
  const path = siteId !== 'MLB' ? '/search/pagos?utm_source=search%2Fsection-categories' : '/search/pagamentos?utm_source=home%2Fsection-categories';
  return base + path;
};
