const MAX_RELATED_ITEMS = 10;

const React = require('react');
const PropTypes = require('prop-types');
const { Pill } = require('@andes/badge');
const { useI18n } = require('nordic/i18n');
const { mergeLinksAndSources } = require('./helpers');
const { getEventTracker } = require('../../../../../../utils/event-tracker');

const RelatedLinks = ({ list, sources }) => {
  const [expanded, setExpanded] = React.useState(false);
  const { i18n } = useI18n();
  const Tracker = getEventTracker();

  if (!list?.length && !sources?.length) {
    return null;
  }

  const toggleExpanded = e => {
    e.preventDefault();

    if (expanded) {
      const dropdown = document.querySelector('.search-dropdown');
      const ul = document.querySelector('.search-result-ai__related-links__list');

      if (dropdown && ul) {
        dropdown.scrollTop = ul.offsetTop - 46;
      }
    }

    setExpanded(!expanded);
  };

  /**
   * Sources are links sent by the AI telling us which page was used to the answer.
   * If sources sent, we add the sources as a list item to be rendered as reference.
   */
  const linklist = mergeLinksAndSources(list, sources, MAX_RELATED_ITEMS);

  return (
    <div className="search-result-ai__related-links" data-expanded={expanded}>
      <p data-title/* className="search-result-ai__related-links__title" */>
        {i18n.gettext('Para acceder a informaciones adicionales:')}
      </p>

      <ul className="search-result-ai__related-links__list">
        {linklist.map(details => (
          <li>
            <a
              tabIndex={0}
              target="_blank"
              key={details.endpoint}
              href={details.endpoint}
              rel="noopener noreferrer"
              onClick={() => Tracker.send('mpdevelopers/search/click_on_related')}
            >
              {i18n.gettext(details.title || '')}

              {!!details?.section && (
                <Pill
                  size="small"
                  color="accent"
                  hierarchy="quiet"
                  roundedCorners={['top-right', 'top-left', 'bottom-right', 'bottom-left']}
                >
                  <span style={{
                    height: '10px',
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                    {i18n.gettext(details.section)}
                  </span>
                </Pill>
              )}
            </a>
          </li>
        ))}
      </ul>

      {linklist.length > 5 && (
        <p
          href="#"
          role="button"
          className="links-more-less"
          onClick={toggleExpanded}
          data-icon={expanded ? '-' : '+'}
        >
          {expanded
            ? <>{i18n.gettext('Ver menos')}</>
            : <>{i18n.gettext('Ver más')}</>}
        </p>
      )}
    </div>
  );
};

RelatedLinks.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
  list: PropTypes.arrayOf.isRequired,
};

module.exports = RelatedLinks;
