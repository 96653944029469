require('../pages/home/styles.scss');

const React = require('react');
const hydrate = require('nordic/hydrate');
const I18n = require('nordic/i18n');
const I18nProvider = require('nordic/i18n/I18nProvider');
const { AndesProvider } = require('@andes/context');
const View = require('../pages/home/view');

const props = window.__PRELOADED_STATE__; // eslint-disable-line no-underscore-dangle

const i18n = new I18n({
  translations: props.translations,
});

hydrate(
  <I18nProvider i18n={i18n}>
    <AndesProvider locale={props.locale} device={props.device}>
      <View {...props} />
    </AndesProvider>
  </I18nProvider>,
  document.getElementById('root-app'),
);
