/* eslint-disable max-len */

const pros = (i18n, siteId) => {
  switch (siteId) {
    case 'MLB':
      return [
        { title: i18n.gettext('Promoción de servicios'), description: i18n.gettext('Forma parte de nuestro Centro de Partners y promociónate ante miles de vendedores.') },
        { title: i18n.gettext('Atención prioritaria'), description: i18n.gettext('Tus consultas técnicas tienen prioridad de atención en el soporte a desarrolladores.') },
        { title: i18n.gettext('Incentivo Financiero'), description: i18n.gettext('Gana dinero por todas las integraciones que hagas con Mercado Pago.') },
        { title: i18n.gettext('Comunidad exclusiva'), description: i18n.gettext('Recibe invitaciones a eventos, pruebas de producto y noticias antes que todos.') },
      ];
    case 'MLA':
      return [
        { title: i18n.gettext('Promoción de servicios'), description: i18n.gettext('Forma parte de nuestro Centro de Partners y promociónate ante miles de vendedores.') },
        { title: i18n.gettext('Atención prioritaria'), description: i18n.gettext('Tus consultas técnicas tienen prioridad de atención en el soporte a desarrolladores.') },
        { title: i18n.gettext('Comunidad exclusiva'), description: i18n.gettext('Recibe invitaciones a eventos, pruebas de producto y noticias antes que todos.') },
      ];
    case 'MPE':
    case 'MCO':
    case 'MLC':
    case 'MLU':
      return [
        { title: i18n.gettext('Atención prioritaria'), description: i18n.gettext('Tus consultas técnicas tienen prioridad de atención en el soporte a desarrolladores.') },
        { title: i18n.gettext('Comunidad exclusiva'), description: i18n.gettext('Recibe invitaciones a eventos, pruebas de producto y noticias antes que todos.') },
        { title: i18n.gettext('Reconocimiento'), description: i18n.gettext('Destácate en el mercado del desarrollo como un profesional certificado.') },
      ];
    case 'MLM':
      return [
        { title: i18n.gettext('Incentivo Financiero'), description: i18n.gettext('Gana dinero por todas las integraciones que hagas con Mercado Pago.') },
        { title: i18n.gettext('Sello de Partners'), description: i18n.gettext('Destaca entre los demás profesionales comprobando tus habilidades para integrar nuestros Checkouts.') },
        { title: i18n.gettext('Comunidad Discord'), description: i18n.gettext('Súmate a nuestra comunidad de devs y contáctate con otras personas certificadas en el programa.') },
        { title: i18n.gettext('Tasa preferencial a tus clientes'), description: i18n.gettext('Ofrece una tasa preferencial a tus clientes por haber integrado a través de ti.') },
      ];
    default:
      return [
        { title: i18n.gettext('Atención prioritaria'), description: i18n.gettext('Tus consultas técnicas tienen prioridad de atención en el soporte a desarrolladores.') },
        { title: i18n.gettext('Comunidad exclusiva'), description: i18n.gettext('Recibe invitaciones a eventos, pruebas de producto y noticias antes que todos.') },
        { title: i18n.gettext('Reconocimiento'), description: i18n.gettext('Destácate en el mercado del desarrollo como un profesional certificado.') },
      ];
  }
};

module.exports = pros;
