/* eslint-disable nordic/no-script-tags */
const React = require('react');
const { Head } = require('nordic/head');
const { Script } = require('nordic/script');
const GOOGLE_TAG_MANAGER_ID = 'GTM-5TD2V4H';

const isAvailable = () => [
  'test',
  'production',
  'development',
  'alpha',
].includes(process.env.NODE_ENV);

module.exports = function ConfigureGTM() {
  return isAvailable() && (
    <Head>
      <Script>
        {`
    (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${GOOGLE_TAG_MANAGER_ID}');
  `}
      </Script>
    </Head>
  );
};
