/* eslint-disable max-len */
const React = require('react');

const HomePHPImage = () => (
  <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M27.785 45.9988C29.145 45.9988 30.1604 45.7157 30.8028 45.1577C31.4384 44.6054 31.8774 43.6485 32.1074 42.3138C32.322 41.0662 32.2402 40.1951 31.8646 39.725C31.4806 39.2448 30.6502 39.0013 29.3967 39.0013H27.2231L26.0182 45.9988H27.785ZM20.676 54.6184C20.5751 54.6184 20.4793 54.5675 20.4151 54.4794C20.3509 54.3915 20.3248 54.2757 20.3441 54.1639L23.5375 35.6205C23.5684 35.4413 23.7076 35.3117 23.8695 35.3117H30.752C32.915 35.3117 34.525 35.9746 35.537 37.2821C36.5544 38.5965 36.8685 40.4338 36.4707 42.7428C36.3088 43.6835 36.0304 44.5572 35.6432 45.3394C35.2555 46.1224 34.7431 46.847 34.1201 47.4933C33.3743 48.2811 32.5302 48.8523 31.6135 49.1885C30.7113 49.5201 29.5527 49.6883 28.1698 49.6883H25.383L24.5871 54.3095C24.5562 54.4888 24.4171 54.6184 24.2552 54.6184H20.676Z"
      fill="#6682B6"
    />
    <path
      d="M27.5022 39.383H29.397C30.9099 39.383 31.4354 39.7576 31.614 39.9809C31.9103 40.3517 31.9662 41.1333 31.7758 42.2413C31.5618 43.4818 31.1652 44.3613 30.5969 44.8552C30.015 45.3608 29.0689 45.617 27.7853 45.617H26.4287L27.5022 39.383ZM30.7523 34.93H23.8697C23.5459 34.93 23.2676 35.1891 23.2058 35.5477L20.0124 54.0911C19.9739 54.3149 20.026 54.5463 20.1544 54.7223C20.2829 54.8982 20.4743 55 20.6763 55H24.2554C24.5793 55 24.8576 54.741 24.9194 54.3823L25.6621 50.07H28.1701C29.5885 50.07 30.7825 49.8954 31.7187 49.5513C32.6815 49.1982 33.5674 48.5996 34.3514 47.7721C34.9998 47.0989 35.5339 46.3431 35.9386 45.5257C36.3434 44.708 36.6343 43.7962 36.803 42.8155C37.2218 40.385 36.8816 38.4387 35.7919 37.031C34.7129 35.6369 33.0172 34.93 30.7523 34.93ZM25.6082 46.3804H27.7853C29.2283 46.3804 30.3032 46.0737 31.0094 45.46C31.7158 44.8463 32.1922 43.822 32.4397 42.3866C32.6766 41.0085 32.5687 40.036 32.1161 39.4695C31.6629 38.9029 30.7567 38.6196 29.397 38.6196H26.9447L25.6082 46.3804ZM30.7523 35.6934C32.8228 35.6934 34.3332 36.3066 35.2826 37.5332C36.2319 38.7598 36.5178 40.4719 36.139 42.67C35.9831 43.5754 35.7195 44.4033 35.3482 45.1533C34.9766 45.9038 34.4913 46.5901 33.8916 47.2123C33.1769 47.9672 32.3829 48.5051 31.5093 48.8254C30.6356 49.1467 29.5229 49.3066 28.1701 49.3066H25.1044L24.2554 54.2366H20.6763L23.8697 35.6934H30.7523Z"
      fill="white"
    />
    <path
      d="M44.678 49.6884C44.577 49.6884 44.4813 49.6375 44.4172 49.5494C44.3528 49.4615 44.3268 49.3459 44.3461 49.2339L45.7587 41.0292C45.893 40.2489 45.8599 39.6889 45.6654 39.4522C45.5464 39.3076 45.1888 39.0649 44.1315 39.0649H41.5724L39.7963 49.3794C39.7654 49.5588 39.6262 49.6884 39.4643 49.6884H35.9134C35.8124 49.6884 35.7167 49.6375 35.6524 49.5494C35.5882 49.4615 35.5621 49.3457 35.5814 49.2339L38.7748 30.6905C38.8057 30.5113 38.9449 30.3817 39.1068 30.3817H42.6577C42.7587 30.3817 42.8544 30.4326 42.9187 30.5205C42.9829 30.6086 43.009 30.7242 42.9897 30.8362L42.2191 35.3118H44.9721C47.0695 35.3118 48.4916 35.7291 49.32 36.5876C50.1643 37.4634 50.4278 38.8638 50.1031 40.7503L48.6173 49.3794C48.5865 49.5588 48.4473 49.6884 48.2854 49.6884H44.678Z"
      fill="#6682B6"
    />
    <path
      d="M42.6571 30H39.1061C38.7822 30 38.5039 30.259 38.4421 30.6177L35.2488 49.1611C35.2102 49.3849 35.2623 49.6163 35.3908 49.7922C35.5193 49.9682 35.7107 50.07 35.9127 50.07H39.4637C39.7874 50.07 40.0659 49.8109 40.1277 49.4523L41.8505 39.4466H44.1309C45.1863 39.4466 45.4079 39.701 45.4168 39.712C45.4808 39.7897 45.565 40.1497 45.426 40.9564L44.0134 49.1611C43.9749 49.3849 44.0269 49.6163 44.1554 49.7922C44.284 49.9682 44.4754 50.07 44.6774 50.07H48.2847C48.6084 50.07 48.8869 49.8109 48.9487 49.4523L50.4343 40.8232C50.783 38.7976 50.4847 37.2781 49.5482 36.3066C48.6544 35.3803 47.1574 34.93 44.9715 34.93H42.6285L43.321 30.909C43.3595 30.6852 43.3075 30.4537 43.179 30.2779C43.0505 30.1019 42.8591 30 42.6571 30ZM42.6571 30.7634L41.8081 35.6934H44.9715C46.9619 35.6934 48.3349 36.0855 49.0905 36.8687C49.8461 37.6524 50.0725 38.9223 49.7705 40.6776L48.2847 49.3066H44.6774L46.09 41.102C46.2507 40.1687 46.1917 39.5321 45.9125 39.1926C45.6333 38.8532 45.0393 38.6832 44.1309 38.6832H41.2928L39.4637 49.3066H35.9127L39.1061 30.7634H42.6571Z"
      fill="white"
    />
    <path
      d="M55.7215 45.9988C57.0816 45.9988 58.0969 45.7157 58.7393 45.1577C59.3749 44.6055 59.8139 43.6486 60.0441 42.3138C60.2586 41.0662 60.1769 40.1951 59.8011 39.725C59.4171 39.2448 58.5867 39.0013 57.3332 39.0013H55.1597L53.9547 45.9988H55.7215ZM48.6126 54.6184C48.5117 54.6184 48.4159 54.5675 48.3518 54.4794C48.2875 54.3915 48.2613 54.2757 48.2806 54.1639L51.474 35.6205C51.505 35.4413 51.6441 35.3117 51.8061 35.3117H58.6885C60.8517 35.3117 62.4615 35.9746 63.4736 37.2821C64.491 38.5964 64.805 40.4336 64.4073 42.7428C64.2452 43.6835 63.9669 44.5572 63.5797 45.3394C63.192 46.1224 62.6797 46.847 62.0566 47.4933C61.3108 48.2811 60.4667 48.8523 59.55 49.1885C58.6478 49.5201 57.4892 49.6883 56.1063 49.6883H53.3194L52.5238 54.3095C52.4928 54.4888 52.3537 54.6184 52.1917 54.6184H48.6126Z"
      fill="#6682B6"
    />
    <path
      d="M55.4377 39.383H57.3324C58.8453 39.383 59.3709 39.7576 59.5494 39.9809C59.8458 40.3517 59.9017 41.1333 59.7112 42.2412C59.4972 43.4818 59.1006 44.3613 58.5323 44.8552C57.9504 45.3608 57.0044 45.617 55.7207 45.617H54.3643L55.4377 39.383ZM58.6877 34.93H51.8052C51.4815 34.93 51.2031 35.1891 51.1414 35.5477L47.948 54.0911C47.9094 54.3149 47.9615 54.5463 48.0899 54.7223C48.2184 54.8982 48.4098 55 48.6118 55H52.191C52.5147 55 52.7932 54.741 52.8549 54.3823L53.5975 50.07H56.1056C57.5239 50.07 58.718 49.8954 59.6542 49.5513C60.617 49.1982 61.503 48.5995 62.287 47.7719C62.9355 47.0987 63.4693 46.3431 63.874 45.5257C64.2788 44.708 64.5697 43.7962 64.7384 42.8155C65.1572 40.3849 64.817 38.4385 63.7274 37.0309C62.6483 35.6369 60.9528 34.93 58.6877 34.93ZM53.5437 46.3804H55.7207C57.1638 46.3804 58.2386 46.0737 58.9449 45.46C59.6513 44.8463 60.1276 43.822 60.3752 42.3866C60.6121 41.0085 60.5042 40.036 60.0515 39.4695C59.5984 38.9029 58.6921 38.6196 57.3324 38.6196H54.8801L53.5437 46.3804ZM58.6877 35.6934C60.7582 35.6934 62.2687 36.3066 63.2181 37.5332C64.1675 38.7598 64.4532 40.4719 64.0745 42.67C63.9186 43.5754 63.6549 44.4033 63.2837 45.1533C62.912 45.9038 62.4267 46.5901 61.827 47.2123C61.1123 47.9672 60.3184 48.5051 59.4447 48.8254C58.5711 49.1467 57.4583 49.3066 56.1056 49.3066H53.0398L52.191 54.2366H48.6118L51.8052 35.6934H58.6877Z"
      fill="white"
    />
  </svg>

);

module.exports = HomePHPImage;
