const React = require('react');
const PropTypes = require('prop-types');
const { Pill } = require('@andes/badge');

const ResultItem = ({ result, i18n }) => {
  if (!result || !Object.keys(result)) {
    return null;
  }

  return (
    <div className="result-item">
      <a href={result?.endpoint || result?.url} tabIndex={0}>
        <div className="result-item__head">
          <h3>
            {`${result?.title}`}

            {!!result?.section && (
              <Pill
                size="small"
                color="accent"
                hierarchy="quiet"
                roundedCorners={['top-right', 'top-left', 'bottom-right', 'bottom-left']}
              >
                <span style={{
                  height: '10px',
                  display: 'flex',
                  alignItems: 'center',
                }}>
                  {result.section}
                </span>
              </Pill>
            )}
          </h3>
        </div>

        <div className="result-item__body">
          <p>{i18n.gettext(result?.message || '') || `${i18n.gettext('Saber más')}: ${result?.title}`}...</p>
        </div>
      </a>
    </div>
  );
};

ResultItem.propTypes = {
  result: PropTypes.shape({
    title: PropTypes.string.isRequired,
    section: PropTypes.string.isRequired,
  }).isRequired,
  lang: PropTypes.string.isRequired,
  siteId: PropTypes.string.isRequired,
};

module.exports = ResultItem;
