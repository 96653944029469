/* eslint-disable max-len */
const React = require('react');

const BulletImage = () => (
  <svg width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.18155 7.09361L12.0465 0.228638L13.5893 1.77142L5.18155 10.1792L0.410156 5.40778L1.95293 3.865L5.18155 7.09361Z"
      fill="#5AD2FF"
    />
  </svg>
);

module.exports = BulletImage;
