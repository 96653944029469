const React = require('react');
const CarouselSnapped = require('@andes/carousel-snapped');
const Button = require('@andes/button');
const Image = require('nordic/image');
const { getEventTracker } = require('../../../../../utils/event-tracker');

const { CarouselSnappedSlide } = CarouselSnapped;

const HomeCarousel = ({ children, className }) => {
  const Tracker = getEventTracker();

  return (
    <div className="home-carousel">
      <CarouselSnapped
        className={`carousel carousel-devsite-home-container ${className}`}
        type="full"
        infinite
        onClick={() => { Tracker.send('mpdevelopers/click_home_carrousel_navigation'); }}
        autoplay={{ speed: 5000 }}
      >
        {children}
      </CarouselSnapped>
    </div>
  );
};

HomeCarousel.CarouselItem = ({
  header,
  title,
  subtitle,
  SlideImage,
  className,
  index,
  isMobile,
  buttons,
  background,
}) => {
  const Tracker = getEventTracker();
  const buttonsFiltered = buttons.filter((item) => item.text !== undefined);

  return (
    <CarouselSnappedSlide
      className={`carousel__slide devsite-carousel-item ${className}`}
      index={index}
    >

      <div
        className="wrapper"
        style={{
          background,
        }}
      >
        <div className="text-container">
          {header && isMobile && <p className="header">{header}</p>}
          <h1 className="title">{title}</h1>
          <p className="subtitle">{subtitle}</p>
          {buttonsFiltered.map(button => (
            <Button
              className="slide-button"
              href={button.url}
              title={button.text}
              onClick={() => {
                Tracker.send(`mpdevelopers/home_banner/click_${button.tracking}`);
              }}
            >
              {button.text}
            </Button>
          ))}
        </div>
        <div className="image-container">
          <Image src={SlideImage} />
        </div>
      </div>
    </CarouselSnappedSlide>
  );
};
module.exports = HomeCarousel;
