const { DxEventTracker, dxGTMScriptSource, dxGAScriptSnippet } = require('dx-event-tracker');

const queue = [];
const GAID = 'G-XJRJL56B0Z';
const singleton = { tracker: undefined };
const trackedScopes = ['www', 'alpha'];
const allowedEnvironments = ['production'];

const EventTracker = {
  dxGAScriptSnippet: () => (allowedEnvironments.includes(process.env.NODE_ENV) ? dxGAScriptSnippet(GAID) : ''),
  dxGTMScriptSource: () => (allowedEnvironments.includes(process.env.NODE_ENV) ? dxGTMScriptSource(GAID) : ''),

  configureEventTracker(config) {
    Object.assign(config, {
      debug: false,
      sendToMelidata: false,
      gaAutoSetFields: true,
      googleAnalyticsId: GAID,
      active: EventTracker.mustActivateEventTracker(),
    });

    const tracker = new DxEventTracker(config);
    singleton.tracker = tracker;

    while (queue.length) {
      tracker.send.apply(tracker, queue.pop());
    }
  },

  getEventTracker() {
    return {
      send: (...args) => {
        const { tracker } = singleton;

        tracker
          ? tracker.send.apply(tracker, args)
          : queue.push(args);
      },
    };
  },

  mustActivateEventTracker() {
    if (typeof window === 'undefined') {
      return false;
    }

    if (!allowedEnvironments.includes(process.env.NODE_ENV)) {
      return false;
    }

    const scope = window?.location?.host?.match(/(http(s)?:\/\/)?(?<scope>.*)\.mercadopago\.(com|cl).*/)?.groups?.scope;
    return trackedScopes.includes(scope);
  },
};

module.exports = EventTracker;
