const React = require('react');
const PropTypes = require('prop-types');

const Footer = ({ children }) => (
  <div className="search-result-ai__footer">
    {children}
  </div>
);

Footer.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
  list: PropTypes.arrayOf.isRequired,
};

module.exports = Footer;
