const React = require('react');
const HomeImages = require('../../images');

const { CertificationOrangeImage, CertificationPurpleImage, CertificationBlueImage, ChevronRight } = HomeImages;

const certifications = (lang, siteId, i18n) => {
  const certData = [
    { image: <CertificationBlueImage />, title: i18n.gettext('Checkout Pro Mercado Pago'), description: i18n.gettext('Muestra que tienes las habilidades necesarias para integrar la solución de checkout número 1 de Mercado Pago.'), url: `/developers/${lang}/developer-program/checkout-pro`, linkDescription: i18n.gettext('Saber más'), linkIcon: <ChevronRight />, tracking: 'HOME_DEVPROGRAM_LINK_CHOPRO', label: i18n.gettext('Saber más sobre Checkout Pro') },
    { image: <CertificationPurpleImage />, title: i18n.gettext('WooCommerce Configuration'), description: i18n.gettext('Conoce cómo configurar nuestro plugin en tu sitio. No es necesario tener conocimientos en programación.'), url: `/developers/${lang}/developer-program/woocommerce`, linkDescription: i18n.gettext('Saber más'), linkIcon: <ChevronRight />, tracking: 'HOME_DEVPROGRAM_LINK_WOOCONFIG', label: i18n.gettext('Saber más sobre Woocommerce Configuration') },
    { image: <CertificationOrangeImage />, title: i18n.gettext('Adobe Commerce'), description: i18n.gettext('Comprueba tus conocimientos técnicos sobre la integración de Mercado Pago con la plataforma Adobe Commerce.'), url: `/developers/${lang}/developer-program/adobe-commerce`, linkDescription: i18n.gettext('Saber más'), linkIcon: <ChevronRight />, tracking: 'HOME_DEVPROGRAM_LINK_WOOCONFIG', label: i18n.gettext('Saber más sobre Adobe Commerce') },
  ];
  if (['MLA', 'MLB', 'MLM'].includes(siteId.toUpperCase())) {
    certData.splice(0, 3, { image: <CertificationBlueImage />, title: i18n.gettext('Checkout Pro Mercado Pago'), description: i18n.gettext('Muestra que tienes las habilidades necesarias para integrar la solución de checkout número 1 de Mercado Pago.'), url: `../partners/developers/${lang}/certifications/checkout-pro`, linkDescription: i18n.gettext('Saber más'), linkIcon: <ChevronRight />, tracking: 'HOME_DEVPROGRAM_LINK_CHOPRO', label: i18n.gettext('Saber más sobre Checkout Pro') },
      { image: <CertificationPurpleImage />, title: i18n.gettext('WooCommerce'), description: i18n.gettext('Demuestra tu experiencia en la integración de nuestro plugin en tiendas de la plataforma WooCommerce.'), url: `../partners/developers/${lang}/certifications/woocommerce`, linkDescription: i18n.gettext('Saber más'), linkIcon: <ChevronRight />, tracking: 'HOME_DEVPROGRAM_LINK_WOOCOMMERCE', label: i18n.gettext('Saber más sobre WooCommerce') },
      { image: <CertificationOrangeImage />, title: i18n.gettext('Adobe Commerce'), description: i18n.gettext('Pon a prueba tus conocimientos al integrar el plugin Mercado Pago en tiendas de la plataforma Adobe Commerce.'), url: `../partners/developers/${lang}/certifications/adobe-commerce`, linkDescription: i18n.gettext('Saber más'), linkIcon: <ChevronRight />, tracking: 'HOME_DEVPROGRAM_LINK_ADOBECOMMERCE', label: i18n.gettext('Saber más sobre AdobeCommerce') });
  }
  if (!['MLB'].includes(siteId.toUpperCase())) {
    certData.splice(certData.length, 0, { image: <CertificationPurpleImage />, title: i18n.gettext('WooCommerce Integration'), description: i18n.gettext('Integrá nuestros checkouts en tu tienda WooCommerce. Es necesario tener conocimientos en programación.'), url: `/developers/${lang}/developer-program/woocommerce-devs`, linkDescription: i18n.gettext('Saber más'), linkIcon: <ChevronRight />, tracking: 'HOME_DEVPROGRAM_LINK_WOOINTEGRATION', label: i18n.gettext('Saber más sobre Wooocommerce Integration') });
  }
  return certData;
};
module.exports = certifications;
