/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
const React = require('react');
const PropTypes = require('prop-types');
const Button = require('@andes/button');
const HomeImages = require('../../images');
const HomeDevProgramCarouselMobile = require('./HomeDevProgramCarouselMobile');
const HomeDevProgramCarouselDesktopStandar = require('./HomeDevProgramCarouselDesktopStandar');
const prosItems = require('./pros');
const certificationsData = require('./certifications');
const { getEventTracker } = require('../../../../../utils/event-tracker');

const { CarouselItemMobile } = HomeDevProgramCarouselMobile;
const { CarouselItemDesktopStandar } = HomeDevProgramCarouselDesktopStandar;

const HomeDevProgram = ({ i18n, isMobile, lang, siteId }) => {
  const { HomeDevProgramImageDesktop, HomeDevProgramImage, BulletImage } = HomeImages;
  const certifications = certificationsData(lang, siteId, i18n);
  const pros = prosItems(i18n, siteId);
  const Tracker = getEventTracker();
  const moreInfoButtonLink = ['MLB', 'MLA', 'MLM'].includes(siteId) ? `/partners/developers/${lang}` : `/developers/${lang}/developer-program`;

  return (
    <div className="home-dev-program">
      <div className="home-dev-program__container">
        <div className="home-dev-program__subcontainer">
          <h2 className="home-dev-program__title">{i18n.gettext('Forma parte de nuestro programa gratuito para desarrolladores')}</h2>
          <h3 className="home-dev-program__description">
            {i18n.gettext('Descubre las ventajas exclusivas que recibirás al participar de nuestro Partners Program.')}
          </h3>

          {isMobile
            ? <HomeDevProgramImage className="home-dev-program__main-image" />
            : (<div className="home-dev-program__pros-container">{pros.map((p) => (
              <div className="home-dev-program__pro-item-container">
                <BulletImage />
                <div className="home-dev-program__pro-item">
                  <h3 className="home-dev-program__pro-item-title">{i18n.gettext(p.title)}</h3>
                  <h4 className="home-dev-program__pro-item-decription">{i18n.gettext(p.description)}</h4>
                </div>
              </div>
            ))}
            </div>)}
        </div>
        {isMobile
          ? (<div className="home-dev-program__pros-container">{pros.map((p) => (
            <div className="home-dev-program__pro-item-container">
              <BulletImage />
              <div className="home-dev-program__pro-item">
                <h3 className="home-dev-program__pro-item-title">{i18n.gettext(p.title)}</h3>
                <h4 className="home-dev-program__pro-item-decription">{i18n.gettext(p.description)}</h4>
              </div>
            </div>
          ))}
          </div>)
          : <HomeDevProgramImageDesktop className="home-dev-program__main-image" />}
      </div>
      {isMobile
        ? (<HomeDevProgramCarouselMobile
            className="home-dev-program__carrousel"
            mobile
            type="full"
        >
          {certifications.map((cert, index) => (
            <CarouselItemMobile
              title={i18n.gettext(cert.title)}
              index={index}
              subtitle={i18n.gettext(cert.description)}
              url={cert.url}
              image={cert.image}
              linkDescription={i18n.gettext(cert.linkDescription)}
              linkIcon={cert.linkIcon}
              className="short"
              tracking={cert.tracking}
              label={cert.label}
            />
          ))}
        </HomeDevProgramCarouselMobile>)
        : (<HomeDevProgramCarouselDesktopStandar
            certifications={certifications.length}
            title={i18n.gettext('NUESTRAS CERTIFICACIONES')}
            type="content"
        >
          {certifications.map((cert, index) => (
            <CarouselItemDesktopStandar
              title={i18n.gettext(cert.title)}
              index={index}
              subtitle={i18n.gettext(cert.description)}
              url={cert.url}
              image={cert.image}
              linkDescription={i18n.gettext(cert.linkDescription)}
              linkIcon={cert.linkIcon}
              certifications={certifications.length}
              tracking={cert.tracking}
              label={cert.label}
            />
          ))}
        </HomeDevProgramCarouselDesktopStandar>)}
      <Button
        className="dev_program_plus"
        href={moreInfoButtonLink}
        onClick={() => {
          Tracker.send('mpdevelopers/click_home_devprogram_button_masinfo');
        }}
      >{i18n.gettext('Saber más sobre el programa')}</Button>
    </div>

  );
};
HomeDevProgram.prototype = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }),
  siteId: PropTypes.string.isRequired,
  lang: PropTypes.string.isRequired,
};

HomeDevProgram.defaultProps = {
  i18n: { gettext: () => { } },
};

module.exports = HomeDevProgram;
