const React = require('react');

const Default = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="6"
    fill="none"
    viewBox="0 0 10 6"
  >
    <path
      fill="#000"
      fillOpacity="0.9"
      d="M1.402.355l3.596 3.597 3.6-3.6.849.848-4.449 4.449L.553 1.204l.849-.849z"
    />
  </svg>
);

module.exports = Default;
