/* eslint-disable max-len */
const React = require('react');

const HomeJAVAImage = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M33.8259 52.277C33.8259 52.277 32.1097 53.2751 35.0474 53.6128C38.6063 54.0188 40.4253 53.9606 44.3473 53.2183C44.3473 53.2183 45.3784 53.8648 46.8184 54.4248C38.0264 58.193 26.9202 54.2066 33.8259 52.277Z" fill="#5382A1" />
    <path d="M32.7512 47.3598C32.7512 47.3598 30.8262 48.7847 33.7661 49.0888C37.5679 49.481 40.5703 49.5131 45.7655 48.5127C45.7655 48.5127 46.4841 49.2412 47.614 49.6396C36.9838 52.748 25.1438 49.8847 32.7512 47.3598Z" fill="#5382A1" />
    <path d="M41.8093 39.0187C43.9757 41.5128 41.2401 43.7573 41.2401 43.7573C41.2401 43.7573 46.7409 40.9176 44.2146 37.3617C41.8552 34.0456 40.0458 32.3979 49.841 26.717C49.841 26.717 34.4657 30.5571 41.8093 39.0187Z" fill="#E76F00" />
    <path d="M53.437 55.914C53.437 55.914 54.7071 56.9605 52.0383 57.7701C46.9634 59.3075 30.916 59.7718 26.4581 57.8314C24.8556 57.1343 27.8607 56.1668 28.806 55.9638C29.7919 55.75 30.3553 55.7898 30.3553 55.7898C28.5731 54.5344 18.8362 58.255 25.4094 59.3205C43.3354 62.2276 58.0868 58.0114 53.437 55.914Z" fill="#5382A1" />
    <path d="M34.651 42.2651C34.651 42.2651 26.4883 44.2038 31.7604 44.9079C33.9865 45.2059 38.424 45.1385 42.5575 44.7922C45.9356 44.5072 49.3276 43.9013 49.3276 43.9013C49.3276 43.9013 48.1365 44.4115 47.2747 44.9999C38.9856 47.1799 22.9726 46.1658 27.5825 43.9358C31.4811 42.0513 34.651 42.2651 34.651 42.2651Z" fill="#5382A1" />
    <path d="M49.2944 50.45C57.7206 46.0714 53.8246 41.8635 51.1053 42.4304C50.4388 42.5692 50.1416 42.6894 50.1416 42.6894C50.1416 42.6894 50.389 42.3018 50.8616 42.134C56.2413 40.2426 60.3787 47.7122 49.125 50.6706C49.125 50.6708 49.2554 50.5542 49.2944 50.45Z" fill="#5382A1" />
    <path d="M44.2139 17.5439C44.2139 17.5439 48.8805 22.212 39.7879 29.3903C32.4965 35.1486 38.1252 38.4318 39.7848 42.183C35.5287 38.3429 32.4053 34.9625 34.5007 31.8164C37.5763 27.1981 46.0968 24.959 44.2139 17.5439Z" fill="#E76F00" />
    <path d="M35.4795 62.315C43.5676 62.8327 55.9879 62.0277 56.2821 58.2006C56.2821 58.2006 55.7166 59.6514 49.5977 60.8036C42.6942 62.1028 34.1799 61.9511 29.1299 61.1185C29.13 61.1184 30.1637 61.9741 35.4795 62.315Z" fill="#5382A1" />
  </svg>

);

module.exports = HomeJAVAImage;
