const { Library } = require('dx-devsite-markdown');
const { markdownToTxt } = require('markdown-to-txt');

function extractMessageFromHTML(title, innerHTML, size) {
  try {
    const raw = Object.assign(document.createElement('template'), { innerHTML }).content.textContent.trim();
    const parts = (raw || '').split(/(\r\n|\n|\r)/gm);
    const mustRemoveTitle = parts.length > 1 && parts[0].trim()?.toLocaleLowerCase === title.trim()?.toLocaleLowerCase;
    const pre = (mustRemoveTitle ? parts.slice(1) : parts[0]).join(' ').trim();

    return markdownToTxt(pre).substring(0, size).replace(/ *\[[^)]*\] */g, '');
  } catch (error) {
    console.error(error);
    return '...';
  }
}

module.exports = {
  parseResultItem(result, lang, siteId) {
    if (!result) {
      return null;
    }

    try {
      const { paths } = JSON.parse(result?.body || '{}');
      const title = result?.title || result?.item?.title;
      const endpoint = (result?.url || '').startsWith(`/developers/${lang}/`) ? result.url : `/developers/${lang}${result.url}`;

      let message = '';
      result.title = title;

      if (result.body && paths) {
        const methods = Object.keys(paths || {}).length ? paths[Object.keys(paths)[0]] : [];
        message = methods[Object.keys(methods)[0]]?.description || '';
      } else {
        message = extractMessageFromHTML(result?.title, Library.Parser(result?.content, siteId, lang), 200);
      }

      if (message && typeof message === 'object') {
        message = message[lang] || '';
      }

      return {
        title,
        message,
        endpoint,
        docPath: result?.docPath,
        section: result?.section,
      };
    } catch (error) {
      console.trace('Could not show search result item: ', result, error);
      return null;
    }
  },
};
