/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable max-len */
const React = require('react');
const PropTypes = require('prop-types');
const HomeHelpCard = require('./HomeHelpCard');
const HomeImages = require('../../images');
const { changeDomainUrl } = require('../../../Documentation/components/PartnervVisibility/components/utils');


const HomeHelp = ({ i18n, platform, lang }) => {
  const { HomeHelpContactImage, HomeHelpRedirectImage, HomeHelpStatusImage, HomeDiscordImage, HomeHelpSupportImage, CdpCardHelp } = HomeImages;
  const siteIdAvailables = ['MLB'];
  const allowedSiteIds = ['MLA', 'MLM', 'MLB'];
  const { siteId } = platform;

  return (
    <div className="home-help">
      <h4 className="home-help__main--title">{i18n.gettext('¿Necesitas ayuda?')}</h4>
      <div className="home-help__cards--container">
        <HomeHelpCard
          image={<HomeHelpSupportImage />}
          title={i18n.gettext('Soporte')}
          description={i18n.gettext('Sácate las dudas acerca de las integraciones, cuentas y otros asuntos técnicos con nuestro equipo especializado.')}
          link={`/developers/${lang}/support/center`}
          linkDescription={i18n.gettext('Ir a Soporte')}
          linkIcon={<HomeHelpRedirectImage />}
          isRel={false}
          tracking="HOME_HELP_LINK_SOPORTE"
        />
        <HomeHelpCard
          image={<HomeHelpStatusImage />}
          title={i18n.gettext('Mercado Pago Status')}
          description={i18n.gettext('Haz el seguimiento en tiempo real si las APIs y otros servicios de Mercado Pago están operando normalmente.')}
          link="https://status.mercadopago.com/"
          linkDescription={i18n.gettext('Verificar status')}
          linkIcon={<HomeHelpRedirectImage />}
          isRel
          tracking="HOME_HELP_LINK_ESTATUSMP"
        />
        {allowedSiteIds.includes(platform.siteId)
          && <HomeHelpCard
            image={<CdpCardHelp />}
            title={i18n.gettext('Centro de Partners')}
            description={i18n.gettext('Contrata a un partner que te ayude a integrar nuestras soluciones de manera segura y ágil.')}
            link={changeDomainUrl(siteId)}
            linkDescription={i18n.gettext('Visitar Centro de Partners')}
            linkIcon={<HomeHelpRedirectImage />}
            isRel
            tracking="ACCESS_POINT_CDP/CARD_HOME_HELP"
          />}
        {siteIdAvailables.includes(platform.siteId)
          && <HomeHelpCard
            image={<HomeHelpContactImage />}
            title={i18n.gettext('Contacto Comercial')}
            description={i18n.gettext('Contactate con nuestro equipo comercial para conocer más sobre las ventajas de usar nuestras soluciones de pago para tu negocio.')}
            link="https://www.mercadopago.com.br/quero-usar/"
            linkDescription={i18n.gettext('Contactar')}
            linkIcon={<HomeHelpRedirectImage />}
            isRel={false}
            tracking="HOME_HELP_LINK_COMERCIAL"
          />}
        <HomeHelpCard
          image={<HomeDiscordImage />}
          title={i18n.gettext('Comunidad Discord')}
          description={i18n.gettext('Intercambia conocimiento con otros profesionales de desarrollo en nuestro canal oficial en Discord.')}
          link="https://discord.com/invite/yth5bMKhdn"
          linkDescription={i18n.gettext('Entrar a la comunidad')}
          linkIcon={<HomeHelpRedirectImage />}
          isRel
          tracking="HOME_DISCORD_LINK"
        />
      </div>
    </div>

  );
};
HomeHelp.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }),
  platform: PropTypes.shape({
    siteId: PropTypes.string.isRequired,
  }).isRequired,
  lang: PropTypes.string.isRequired,
};

HomeHelp.defaultProps = {
  i18n: { gettext: () => { } },
};

module.exports = HomeHelp;
