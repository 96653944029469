/* eslint-disable max-len */
const React = require('react');

const HomeDiscordImage = () => (
  <svg className="home-help--image" width="52" height="54" viewBox="0 0 52 54" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-inside-1_191_8704" fill="white">
      <path fillRule="evenodd" clipRule="evenodd" d="M26.2874 52C39.9358 52 51 40.6754 51 26.7059C51 12.7363 39.9358 1.41177 26.2874 1.41177C12.6389 1.41177 1.57471 12.7363 1.57471 26.7059C1.57471 33.6386 4.29966 39.9198 8.7131 44.4888L10.3472 52.945L19.618 51.0682C21.7396 51.6754 23.9764 52 26.2874 52Z" />
    </mask>
    <path fillRule="evenodd" clipRule="evenodd" d="M26.2874 52C39.9358 52 51 40.6754 51 26.7059C51 12.7363 39.9358 1.41177 26.2874 1.41177C12.6389 1.41177 1.57471 12.7363 1.57471 26.7059C1.57471 33.6386 4.29966 39.9198 8.7131 44.4888L10.3472 52.945L19.618 51.0682C21.7396 51.6754 23.9764 52 26.2874 52Z" fill="#5AD2FF" />
    <path d="M8.7131 44.4888L11.6586 43.9196L11.4896 43.0452L10.8708 42.4046L8.7131 44.4888ZM10.3472 52.945L7.40166 53.5142L7.97592 56.4859L10.9424 55.8853L10.3472 52.945ZM19.618 51.0682L20.4435 48.184L19.74 47.9826L19.0228 48.1278L19.618 51.0682ZM48 26.7059C48 39.0847 38.2135 49 26.2874 49V55C41.658 55 54 42.2661 54 26.7059H48ZM26.2874 4.41177C38.2135 4.41177 48 14.327 48 26.7059H54C54 11.1456 41.658 -1.58823 26.2874 -1.58823V4.41177ZM4.57471 26.7059C4.57471 14.327 14.3612 4.41177 26.2874 4.41177V-1.58823C10.9167 -1.58823 -1.42529 11.1456 -1.42529 26.7059H4.57471ZM10.8708 42.4046C6.98275 38.3794 4.57471 32.8397 4.57471 26.7059H-1.42529C-1.42529 34.4375 1.61657 41.4602 6.55537 46.5731L10.8708 42.4046ZM13.2927 52.3758L11.6586 43.9196L5.76759 45.058L7.40166 53.5142L13.2927 52.3758ZM19.0228 48.1278L9.75192 50.0046L10.9424 55.8853L20.2133 54.0085L19.0228 48.1278ZM26.2874 49C24.2588 49 22.2997 48.7152 20.4435 48.184L18.7926 53.9524C21.1795 54.6355 23.6941 55 26.2874 55V49Z" fill="white" mask="url(#path-1-inside-1_191_8704)" />
    <path d="M8.7131 44.4888L9.44948 44.3465L9.40723 44.1279L9.25253 43.9678L8.7131 44.4888ZM10.3472 52.945L9.6108 53.0873L9.75436 53.8302L10.496 53.6801L10.3472 52.945ZM19.618 51.0682L19.8244 50.3471L19.6485 50.2968L19.4692 50.3331L19.618 51.0682ZM50.25 26.7059C50.25 40.2778 39.5052 51.25 26.2874 51.25V52.75C40.3663 52.75 51.75 41.0731 51.75 26.7059H50.25ZM26.2874 2.16177C39.5052 2.16177 50.25 13.134 50.25 26.7059H51.75C51.75 12.3387 40.3663 0.661774 26.2874 0.661774V2.16177ZM2.32471 26.7059C2.32471 13.134 13.0695 2.16177 26.2874 2.16177V0.661774C12.2084 0.661774 0.824707 12.3387 0.824707 26.7059H2.32471ZM9.25253 43.9678C4.97043 39.5347 2.32471 33.4389 2.32471 26.7059H0.824707C0.824707 33.8383 3.62889 40.3049 8.17367 45.0099L9.25253 43.9678ZM11.0835 52.8027L9.44948 44.3465L7.97672 44.6311L9.6108 53.0873L11.0835 52.8027ZM19.4692 50.3331L10.1984 52.2099L10.496 53.6801L19.7668 51.8033L19.4692 50.3331ZM26.2874 51.25C24.047 51.25 21.8796 50.9353 19.8244 50.3471L19.4117 51.7892C21.5996 52.4154 23.9058 52.75 26.2874 52.75V51.25Z" fill="#333333" />
    <path d="M37.185 16.4193L37.0675 16.2474L36.8782 16.1606C34.9133 15.2602 32.8389 14.6214 30.7081 14.2605L30.1863 14.1722L29.9281 14.6341C29.7261 14.9957 29.5372 15.3644 29.3617 15.7395C27.459 15.5007 25.5339 15.5007 23.6312 15.7395C23.4558 15.3645 23.2669 14.9958 23.065 14.6343L22.8065 14.1715L22.284 14.2607C20.1523 14.6245 18.077 15.2647 16.1105 16.1651L15.919 16.2528L15.8012 16.4273C11.9109 22.1886 10.848 27.8324 11.3765 33.3839L11.4082 33.7169L11.6772 33.9158C13.9635 35.6067 16.5227 36.8926 19.2433 37.7177L19.7483 37.8709L20.063 37.4472C20.6823 36.6136 21.2302 35.7292 21.701 34.8035L21.8089 34.5915C23.3339 35.0038 24.911 35.2145 26.4999 35.2145C28.0871 35.2145 29.6626 35.0043 31.1861 34.5928L31.2942 34.8056C31.7646 35.7312 32.3128 36.6152 32.9328 37.4479L33.2472 37.8701L33.7511 37.718C36.4744 36.8961 39.0358 35.6106 41.3229 33.9179L41.5913 33.7193L41.6233 33.387C42.2436 26.9471 40.5582 21.3561 37.185 16.4193ZM21.6826 29.2477C20.7361 29.2477 19.8209 28.3575 19.8209 27.1005C19.8209 25.8305 20.7067 24.9418 21.678 24.9418C22.6616 24.9418 23.5617 25.842 23.5421 27.0887C23.5219 28.3726 22.6413 29.2477 21.6826 29.2477ZM31.3173 29.2477C30.3698 29.2477 29.4579 28.3588 29.4579 27.1005C29.4579 25.8309 30.3433 24.9418 31.3173 24.9418C32.3003 24.9418 33.1941 25.8389 33.1745 27.0887C33.1543 28.3735 32.2752 29.2477 31.3173 29.2477Z" fill="white" stroke="#333333" strokeWidth="1.5" />
  </svg>
);

module.exports = HomeDiscordImage;
