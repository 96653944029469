const React = require('react');
const { Pill } = require('@andes/badge');

function ApiRefPill(props) {
  const { apiMethod } = props;

  let pillColor = 'green';

  switch (apiMethod.toLowerCase()) {
    case 'post':
      pillColor = 'accent';
      break;
    case 'put':
      pillColor = 'orange';
      break;
    case 'get':
      pillColor = 'green';
      break;
    case 'delete':
      pillColor = 'red';
      break;
    case 'patch':
      pillColor = 'accent';
      break;
    default:
      pillColor = 'green';
  }

  return (
    <Pill
      className="api-ref-pill"
      size="small"
      hierarchy="loud"
      color={pillColor}
      roundedCorners={['top-right', 'top-left', 'bottom-right', 'bottom-left']}
    >
      {apiMethod.toUpperCase()}
    </Pill>
  );
}

module.exports = ApiRefPill;
