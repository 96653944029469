/* eslint-disable security/detect-object-injection */
const React = require('react');
const moment = require('moment');
const HomeCarousel = require('../HomeCarousel');

const { CarouselItem } = HomeCarousel;

const HomeHero = ({ lang, isMobile, banners, siteId }) => {
  const dateAux = Date.now();
  const dateFormat = moment(dateAux).format('YYYY/MM/DD h:mm:ss');
  const bannersBySite = banners?.filter(n => n.sites_enabled?.includes(siteId.toLowerCase()));
  const bannersActives = bannersBySite?.filter(n => n.is_active && (n.expiration_date > dateFormat || n.expiration_date === dateAux)
  && dateFormat > n.publication_date);

  return (
    <HomeCarousel>
      {bannersActives?.map((banner, index) => (
        <CarouselItem
          index={index}
          title={banner.content.title[lang]}
          subtitle={banner.content.subtitle[lang]}
          lang={lang}
          buttons={[
            {
              text: banner.content?.cta1?.[lang],
              url: banner?.content?.cta_link1?.[lang],
              tracking: banner?.content?.tracking_cta1,
            },
            {
              text: banner?.content?.cta2?.[lang],
              url: banner?.content?.cta_link2?.[lang],
              tracking: banner?.content?.tracking_cta2,
            },
          ]}
          SlideImage={banner?.content?.image_url}
          isMobile={isMobile}
          background={banner?.content?.background}
        />))}
    </HomeCarousel>
  );
};


module.exports = HomeHero;
