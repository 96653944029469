const React = require('react');

const SendIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    fill="currentColor"
    viewBox="0 0 18 18"
  >
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.77963 1.94099C2.57484 1.83859 2.33045 1.85637 2.14264 1.98733C1.95483 2.11829 1.85366 2.34146 1.87894 2.56902L2.35009 7.77048C2.40475 8.37391 2.88431 8.85126 3.48799 8.90313L3.70075 8.9214L9.55482 9.40127C9.81918 9.48058 10.0002 9.7239 10.0002 9.99991C10.0002 10.2759 9.81918 10.5192 9.55482 10.5986L3.70075 11.0784L3.48799 11.0967C2.88431 11.1486 2.40475 11.6259 2.35009 12.2293L1.87894 17.431C1.85366 17.6585 1.95483 17.8817 2.14264 18.0127C2.33045 18.1436 2.57484 18.1614 2.77963 18.059L17.7796 10.559C17.9914 10.4531 18.1251 10.2367 18.1251 10C18.1251 9.76327 17.9914 9.54685 17.7796 9.44098L2.77963 1.94099Z"
      clipRule="evenodd"
    />
  </svg>
);

module.exports = SendIcon;
