/* eslint-disable react/prop-types */
const React = require('react');

let incid = 0;

const UserHistory = ({ i18n, items, title, onClickItem }) => {
  const except = (result) => {
    const answer = result?.llm?.answer;

    if (answer) {
      return `${answer.substring(0, 200)}...`;
    }

    return i18n.gettext('Resultados disponibles: {0}?', result?.classic?.length);
  };

  return (
    <div className="result-list-wrapper">
      {Boolean(title) && (
        <p className="result-box-header" style={{ marginTop: 0 }}>
          {title}
        </p>
      )}

      {items.map(item => {
        return (
          <div
            tabIndex={0}
            key={++incid}
            className="result-item"
            style={{ cursor: 'pointer' }}
            onClick={() => onClickItem && onClickItem(item)}
          >
            <div className="result-item__head">
              <h3>
                {item.query.text}
              </h3>
            </div>

            <div className="result-item__body">
              <p>{except(item)}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

module.exports = UserHistory;
