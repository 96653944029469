const React = require('react');
const Button = require('@andes/button');
const PropTypes = require('prop-types');
const Search24 = require('@andes/icons/Search24');
const ChevronRight12 = require('@andes/icons/ChevronRight12');
const { useI18n } = require('nordic/i18n');

const Suggestions = ({ items, max, onClickSuggestion }) => {
  const { i18n } = useI18n();
  const MAX_ITEMS = max || 5;

  if (!items || !Array.isArray(items) || !items.length) {
    return null;
  }

  const handleSuggestionClick = (item) => {
    if (onClickSuggestion) {
      onClickSuggestion(item);
    }
  };

  return (
    <div className="suggestions">
      <div className="suggestions__head">
        <h3>{i18n.gettext('BÚSQUEDAS RELACIONADAS')}</h3>
      </div>

      <ul className="suggestions__list">
        {items.slice(0, MAX_ITEMS).map(item => (
          <li className="suggestions__list__item">
            <Button
              key={item}
              hierarchy="transparent"
              onClick={() => handleSuggestionClick(item)}
            >
              <Search24 color="rgba(0, 158, 227, 1)" />

              <div className="suggestions__list__item__label">
                {item}
              </div>

              <ChevronRight12 color="rgba(0, 0, 0, 0.55)" />
            </Button>
          </li>
        ))}
      </ul>
    </div>
  );
};

Suggestions.propTypes = {
  result: PropTypes.shape({
    title: PropTypes.string.isRequired,
    section: PropTypes.string.isRequired,
  }).isRequired,
  max: PropTypes.number,
};

module.exports = Suggestions;
