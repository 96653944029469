/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/destructuring-assignment */
const React = require('react');
const ExternalLink = require('../images/ExternalLink');

function DummyLink(props) {
  const className = `${props.className} link-new-page`;
  return (
    <a href={props.to} {...props} className={className} target={props.openOnSame ? '' : '_blank'} onClick={undefined}>
      {props.children}
      {!props.openOnSame
      && <ExternalLink className="external-link-image" />}

    </a>
  );
}

module.exports = DummyLink;
