/* eslint-disable max-len */
const React = require('react');

const HomeRubyImage = () => (
  <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M53.5306 48.9383L31.0312 62.2982L60.1636 60.3214L62.4074 30.946L53.5306 48.9383Z"
      fill="url(#paint0_linear_1117_168782)"
    />
    <path
      d="M60.212 60.3014L57.7081 43.0191L50.8877 52.0252L60.212 60.3014Z"
      fill="url(#paint1_linear_1117_168782)"
    />
    <path
      d="M60.2454 60.3013L41.9012 58.8613L31.1289 62.2607L60.2454 60.3013Z"
      fill="url(#paint2_linear_1117_168782)"
    />
    <path
      d="M31.1548 62.2645L35.7375 47.2516L25.6533 49.4079L31.1548 62.2645Z"
      fill="url(#paint3_linear_1117_168782)"
    />
    <path
      d="M50.8853 52.0749L46.6687 35.5584L34.6016 46.8697L50.8853 52.0749Z"
      fill="url(#paint4_linear_1117_168782)"
    />
    <path
      d="M61.4514 35.7911L50.0446 26.4747L46.8682 36.7439L61.4514 35.7911Z"
      fill="url(#paint5_linear_1117_168782)"
    />
    <path
      d="M56.1172 23.1532L49.4083 26.8606L45.1758 23.1034L56.1172 23.1532Z"
      fill="url(#paint6_linear_1117_168782)"
    />
    <path
      d="M23 54.4438L25.8104 49.3182L23.537 43.212L23 54.4438Z"
      fill="url(#paint7_linear_1117_168782)"
    />
    <path
      d="M23.3857 43.0191L25.6731 49.5071L35.612 47.2773L46.9591 36.7317L50.1614 26.5603L45.1191 23L36.5466 26.2082C33.8458 28.7203 28.6048 33.6908 28.4159 33.7842C28.2289 33.8797 24.955 40.0674 23.3857 43.0191Z"
      fill="white"
    />
    <path
      d="M31.4174 31.3637C37.271 25.5599 44.8172 22.1309 47.7131 25.0527C50.6073 27.9745 47.5381 35.0751 41.6845 40.877C35.831 46.6789 28.3783 50.2968 25.4843 47.375C22.5883 44.4552 25.5639 37.1656 31.4174 31.3637Z"
      fill="url(#paint8_linear_1117_168782)"
    />
    <path
      d="M31.1543 62.2585L35.7011 47.1979L50.8014 52.049C45.3417 57.1687 39.2693 61.4967 31.1543 62.2585Z"
      fill="url(#paint9_linear_1117_168782)"
    />
    <path
      d="M46.9795 36.7021L50.856 52.057C55.4167 47.2616 59.5101 42.1061 61.5148 35.7295L46.9795 36.7021Z"
      fill="url(#paint10_linear_1117_168782)"
    />
    <path
      d="M61.4754 35.807C63.0268 31.125 63.3848 24.4082 56.0691 23.1611L50.0664 26.4767L61.4754 35.807Z"
      fill="url(#paint11_linear_1117_168782)"
    />
    <path
      d="M23 54.3762C23.2148 62.1036 28.7899 62.2187 31.1648 62.2866L25.6792 49.4753L23 54.3762Z"
      fill="#9E1209"
    />
    <path
      d="M47.001 36.7259C50.5056 38.88 57.5686 43.206 57.7118 43.2856C57.9344 43.4109 60.757 38.526 61.3974 35.7653L47.001 36.7259Z"
      fill="url(#paint12_radial_1117_168782)"
    />
    <path
      d="M35.6963 47.1979L41.7746 58.9249C45.3687 56.9757 48.1831 54.6009 50.7608 52.057L35.6963 47.1979Z"
      fill="url(#paint13_radial_1117_168782)"
    />
    <path
      d="M25.6552 49.4912L24.7939 59.7464C26.4189 61.9661 28.6546 62.159 30.9996 61.986C29.303 57.7634 25.9137 49.3202 25.6552 49.4912Z"
      fill="url(#paint14_linear_1117_168782)"
    />
    <path
      d="M50.0303 26.5026L62.1054 28.1972C61.4609 25.4664 59.4819 23.7041 56.1086 23.1532L50.0303 26.5026Z"
      fill="url(#paint15_linear_1117_168782)"
    />
    <defs>
      <linearGradient id="paint0_linear_1117_168782" x1="57.6227" y1="65.8722" x2="49.3188" y2="51.189" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FB7655" />
        <stop
          offset="0.41"
          stopColor="#E42B1E"
        />
        <stop
          offset="0.99"
          stopColor="#990000"
        />
        <stop
          offset="1"
          stopColor="#990000"
        />
      </linearGradient>
      <linearGradient id="paint1_linear_1117_168782" x1="61.7646" y1="53.5423" x2="50.0915" y2="51.2621" gradientUnits="userSpaceOnUse">
        <stop
          stopColor="#871101"
        />
        <stop
          offset="0.99"
          stopColor="#911209"
        />
        <stop
          offset="1"
          stopColor="#911209"
        />
      </linearGradient>
      <linearGradient id="paint2_linear_1117_168782" x1="53.1918" y1="66.317" x2="53.0414" y2="58.9129" gradientUnits="userSpaceOnUse">
        <stop
          stopColor="#871101"
        />
        <stop
          offset="0.99"
          stopColor="#911209"
        />
        <stop
          offset="1"
          stopColor="#911209"
        />
      </linearGradient>
      <linearGradient id="paint3_linear_1117_168782" x1="30.6966" y1="48.3378" x2="34.1674" y2="58.5149" gradientUnits="userSpaceOnUse">
        <stop
          stopColor="white"
        />
        <stop
          offset="0.23"
          stopColor="#E57252"
        />
        <stop
          offset="0.46"
          stopColor="#DE3B20"
        />
        <stop
          offset="0.99"
          stopColor="#A60003"
        />
        <stop
          offset="1"
          stopColor="#A60003"
        />
      </linearGradient>
      <linearGradient id="paint4_linear_1117_168782" x1="42.1205" y1="38.2585" x2="42.75" y2="49.2738" gradientUnits="userSpaceOnUse">
        <stop
          stopColor="white"
        />
        <stop
          offset="0.23"
          stopColor="#E4714E"
        />
        <stop
          offset="0.56"
          stopColor="#BE1A0D"
        />
        <stop
          offset="0.99"
          stopColor="#A80D00"
        />
        <stop
          offset="1"
          stopColor="#A80D00"
        />
      </linearGradient>
      <linearGradient id="paint5_linear_1117_168782" x1="52.2589" y1="28.0761" x2="53.1794" y2="36.0763" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop
          offset="0.18"
          stopColor="#E46342"
        />
        <stop
          offset="0.4"
          stopColor="#C82410"
        />
        <stop
          offset="0.99"
          stopColor="#A80D00"
        />
        <stop
          offset="1"
          stopColor="#A80D00"
        />
      </linearGradient>
      <linearGradient id="paint6_linear_1117_168782" x1="46.6648" y1="25.2956" x2="47.9626" y2="19.7924" gradientUnits="userSpaceOnUse">
        <stop
          stopColor="white"
        />
        <stop
          offset="0.54"
          stopColor="#C81F11"
        />
        <stop
          offset="0.99"
          stopColor="#BF0905"
        />
        <stop
          offset="1"
          stopColor="#BF0905"
        />
      </linearGradient>
      <linearGradient id="paint7_linear_1117_168782" x1="23.7764" y1="45.5858" x2="27.1705" y2="47.7134" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" />
        <stop
          offset="0.31"
          stopColor="#DE4024"
        />
        <stop
          offset="0.99"
          stopColor="#BF190B"
        />
        <stop
          offset="1"
          stopColor="#BF190B"
        />
      </linearGradient>
      <linearGradient id="paint8_linear_1117_168782" x1="19.3094" y1="53.85" x2="49.8096" y2="22.3979" gradientUnits="userSpaceOnUse">
        <stop
          stopColor="#BD0012"
        />
        <stop
          offset="0.07"
          stopColor="white"
        />
        <stop
          offset="0.17"
          stopColor="white"
        />
        <stop
          offset="0.27"
          stopColor="#C82F1C"
        />
        <stop
          offset="0.33"
          stopColor="#820C01"
        />
        <stop
          offset="0.46"
          stopColor="#A31601"
        />
        <stop
          offset="0.72"
          stopColor="#B31301"
        />
        <stop
          offset="0.99"
          stopColor="#E82609"
        />
        <stop
          offset="1"
          stopColor="#E82609"
        />
      </linearGradient>
      <linearGradient id="paint9_linear_1117_168782" x1="42.7052" y1="57.0181" x2="34.0738" y2="53.3927" gradientUnits="userSpaceOnUse">
        <stop
          stopColor="#8C0C01"
        />
        <stop
          offset="0.54"
          stopColor="#990C00"
        />
        <stop
          offset="0.99"
          stopColor="#A80D0E"
        />
        <stop
          offset="1"
          stopColor="#A80D0E"
        />
      </linearGradient>
      <linearGradient id="paint10_linear_1117_168782" x1="58.5088" y1="45.9756" x2="49.6178" y2="39.6603" gradientUnits="userSpaceOnUse">
        <stop
          stopColor="#7E110B"
        />
        <stop
          offset="0.99"
          stopColor="#9E0C00"
        />
        <stop
          offset="1"
          stopColor="#9E0C00"
        />
      </linearGradient>
      <linearGradient id="paint11_linear_1117_168782" x1="61.5119" y1="32.5346" x2="57.3308" y2="28.2902" gradientUnits="userSpaceOnUse">
        <stop
          stopColor="#79130D"
        />
        <stop
          offset="0.99"
          stopColor="#9E120B"
        />
        <stop
          offset="1"
          stopColor="#9E120B"
        />
      </linearGradient>
      <radialGradient id="paint12_radial_1117_168782" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(51.608 38.7901) scale(10.016 5.23377)">
        <stop
          stopColor="#A80D00"
        />
        <stop
          offset="0.99"
          stopColor="#7E0E08"
        />
        <stop
          offset="1"
          stopColor="#7E0E08"
        />
      </radialGradient>
      <radialGradient id="paint13_radial_1117_168782" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(37.7374 51.9896) scale(13.315 10.3651)">
        <stop
          stopColor="#A30C00"
        />
        <stop
          offset="0.99"
          stopColor="#800E08"
        />
        <stop
          offset="1"
          stopColor="#800E08"
        />
      </radialGradient>
      <linearGradient id="paint14_linear_1117_168782" x1="28.3045" y1="62.2497" x2="20.8081" y2="56.0271" gradientUnits="userSpaceOnUse">
        <stop
          stopColor="#8B2114"
        />
        <stop
          offset="0.43"
          stopColor="#9E100A"
        />
        <stop
          offset="0.99"
          stopColor="#B3100C"
        />
        <stop
          offset="1"
          stopColor="#B3100C"
        />
      </linearGradient>
      <linearGradient id="paint15_linear_1117_168782" x1="53.7578" y1="24.9488" x2="55.8856" y2="30.3315" gradientUnits="userSpaceOnUse">
        <stop
          stopColor="#B31000"
        />
        <stop
          offset="0.44"
          stopColor="#910F08"
        />
        <stop
          offset="0.99"
          stopColor="#791C12"
        />
        <stop
          offset="1"
          stopColor="#791C12"
        />
      </linearGradient>
    </defs>
  </svg>

);

module.exports = HomeRubyImage;
