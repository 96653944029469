/* eslint-disable max-len */
const React = require('react');

const HomeHelpRedirectImage = () => (
  <svg className="Home-Help_Redirect" width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 1H4C2.34315 1 1 2.34315 1 4V12C1 13.6569 2.34315 15 4 15H12C13.6569 15 15 13.6569 15 12V7.99997M8 7.99997L15 1M15 1V4.49995M15 1H11.5"
      stroke="#009EE3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

module.exports = HomeHelpRedirectImage;
