const React = require('react');
const PropTypes = require('prop-types');
const { useI18n } = require('nordic/i18n');
const { createPortal } = require('react-dom');
const SearchInput = require('./components/SearchInput');
const SearchResults = require('./components/SearchResults');
const SearchHistory = require('./components/SearchHistory');
const { assistent, isAIEnabled } = require('./utils/search-client');
const { getEventTracker } = require('../../../utils/event-tracker');
const DebugDetails = require('./components/DebugDetails');

const { useState, useEffect, useRef } = React;

const SearchBar = ({ lang, isMobile, siteId, layout, placeholder, portal }) => {
  const { i18n } = useI18n();
  const Tracker = getEventTracker();
  const $el = useRef();

  const [term, setTerm] = useState('');
  const [active, setActive] = useState(false);
  const [results, setResults] = useState({});
  const [empty, setEmpty] = useState(true);
  const [loading, setLoading] = useState(false);
  const [hasAIAnswer, setHasAIAnswer] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [loadingHistory, setLoadingHistory] = useState(false);

  const transport = (component) => {
    if (typeof window !== 'undefined' && portal) {
      const content = <span className="search-input-container-transported">{component}</span>;
      const target = document.querySelector(portal);
      if (!target) {
        return component;
      }
      return createPortal(content, target);
    }
    return component;
  };

  const reset = () => {
    setShowResults(false);
    setLoading(false);
    setEmpty(true);
    setResults({});
  };

  const forceInputValue = v => {
    const holder = portal ? $el?.current.getRootNode().querySelector(portal) : $el.current;
    const input = holder?.querySelector('[name=ai-search-input]');

    if (input) {
      input.value = v;

      input.dispatchEvent(new Event('input', {
        bubbles: true,
        cancelable: true,
      }));
    }
  };

  const loadHistoryItem = item => {
    reset();
    setLoadingHistory(true);
    setLoading(true);
    setShowResults(true);
    setResults(item);
    forceInputValue(item?.query.text);

    setTimeout(() => {
      setLoading(false);
      Tracker.send('mpdevelopers/search/loaded_user_history');
    }, 600);
  };

  const onBackdropClick = () => {
    reset();
    setActive(false);
    forceInputValue('');
  };

  const doSearch = (inputTerm = term, options = {}) => {
    if (loadingHistory) {
      return false;
    }

    reset();
    setShowResults(inputTerm?.length > 2);

    if (inputTerm?.length > 2) {
      setLoading(true);

      Tracker.send('mpdevelopers/search/start', {
        term: inputTerm,
        lang,
        siteId,
      });

      const LLM = assistent({
        lang,
        site: siteId,
      });

      const startTime = (new Date()).getTime();

      LLM.ask(inputTerm, options)
        .question
        .then(resp => {
          Tracker.send('mpdevelopers/search/success');

          if (resp.llm.html) {
            Tracker.send('mpdevelopers/search/ai_answer', {
              term: inputTerm,
              lang,
              siteId,
            });
          }

          if (resp.classic.length) {
            Tracker.send('mpdevelopers/search/classic_answer', {
              term: inputTerm,
              lang,
              siteId,
              amount: resp?.classic?.length,
            });
          }

          setResults(resp);
        })
        .catch(error => {
          Tracker.send('mpdevelopers/search/error', { message: error?.message });
        })
        .finally(() => {
          const endTime = (new Date()).getTime();
          const responseTime = (endTime - startTime) / 1000;

          Tracker.send('mpdevelopers/search/done', {
            term: inputTerm,
            responseTime,
            hasAIAnswer: Boolean(results?.llm?.html),
            hasClassicAnswer: Boolean(results?.classic?.length),
          });

          setLoading(false);
        });
    }

    return true;
  };

  const loadSuggestionItem = item => {
    Tracker.send('mpdevelopers/search/suggestion-click', { item });
    forceInputValue(item);
    doSearch(item, { was_suggestion: true });
  };

  const listenToCloseOnESC = () => {
    if (typeof window !== 'undefined') {
      const eventAction = active ? 'addEventListener' : 'removeEventListener';

      if (!window.dxclosesearchbar) {
        window.dxclosesearchbar = (e) => {
          if (e.key === 'Escape') {
            setActive(false);
            const input = $el?.current.querySelector('[name=ai-search-input]');

            if (input) {
              input.blur();
            }
          }
        };
      }

      window[eventAction]('keydown', window.dxclosesearchbar);

      if (!active) {
        window.dxclosesearchbar = null;
      }
    }
  };

  useEffect(() => {
    setHasAIAnswer(results?.llm?.html);
    setEmpty(!results?.classic?.length && !results?.llm?.html);
  }, [results]);

  useEffect(() => {
    if (isAIEnabled(siteId)) {
      if (term.length < 3) reset();
    } else {
      doSearch();
    }

    if (loadingHistory) {
      setLoadingHistory(false);
    }
  }, [term]);

  useEffect(() => {
    Tracker.send(`mpdevelopers/search/toggle/${active ? 'open' : 'close'}`);
    listenToCloseOnESC();
  }, [active]);

  return (
    <div className="search-ai-holder" data-layout={layout} ref={$el}>
      <div className="search-container" data-auto-hide={!active && Boolean(portal)}>
        <div className="search-container__header">
          {siteId === 'MLC'
            ? i18n.gettext('¿Qué estás buscando?')
            : i18n.gettext('¿Qué documentación quieres buscar?')}
        </div>

        <div className="search-container__content" data-has-portal={Boolean(portal) || null}>
          {transport(
            <SearchInput
              i18n={i18n}
              lang={lang}
              siteId={siteId}
              layout={layout}
              onTyped={setTerm}
              isActive={active}
              onSubmit={doSearch}
              placeholder={placeholder}
              onFocus={() => setActive(true)}
              allowSearching={term?.length > 2}
            />,
          )}

          {active && (
            <div className="search-dropdown">
              <div className="search-dropdown__content" tabIndex={0}>
                {term === '__' && (
                  <DebugDetails />
                )}

                {showResults
                  && <SearchResults
                    i18n={i18n}
                    term={term}
                    lang={lang}
                    empty={empty}
                    siteId={siteId}
                    results={results}
                    loading={loading}
                    isMobile={isMobile}
                    showResults={showResults}
                    hasAIAnswer={hasAIAnswer}
                    onClickSuggestion={loadSuggestionItem}
                  />}

                {(!showResults && term === '')
                  && <SearchHistory
                    i18n={i18n}
                    isMobile={isMobile}
                    onClickUserHistoryItem={loadHistoryItem}
                  />}
              </div>
            </div>
          )}

          {active && (
            <div
              onClick={onBackdropClick}
              className="search-backdrop"
            />
          )}
        </div>
      </div>
    </div>
  );
};

SearchBar.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }),
  lang: PropTypes.string.isRequired,
  isMobile: PropTypes.bool.isRequired,
  siteId: PropTypes.string.isRequired,
};

module.exports = SearchBar;
