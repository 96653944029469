/* eslint-disable max-len */
const React = require('react');

const HomeHelpStatusImage = () => (
  <svg className="home-help--image" width="52" height="40" viewBox="0 0 52 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="24.2805" y="32.0762" width="2.30136" height="4.60273" fill="white" stroke="#333333" strokeWidth="1.5" />
    <path d="M2.74976 5.51709H49.2492V26.4768C49.2492 28.9621 47.2345 30.9768 44.7492 30.9768H7.24975C4.76447 30.9768 2.74976 28.9621 2.74976 26.4768V5.51709Z" fill="#5AD2FF" stroke="white" strokeWidth="3" />
    <path d="M1.24976 4.01709H50.7492V26.4768C50.7492 29.7905 48.063 32.4768 44.7492 32.4768H7.24975C3.93604 32.4768 1.24976 29.7905 1.24976 26.4768V4.01709Z" stroke="#333333" strokeWidth="1.5" />
    <path d="M1.24969 2.9815C1.24969 2.00688 2.03978 1.2168 3.0144 1.2168H48.9934C49.968 1.2168 50.7581 2.00688 50.7581 2.9815V4.11125H1.24969V2.9815Z" fill="white" stroke="#333333" strokeWidth="1.5" />
    <ellipse cx="25.888" cy="2.66055" rx="0.441175" ry="0.440824" fill="#333333" />
    <path d="M36.5664 38.4231C36.5664 36.4964 35.0045 34.9346 33.0779 34.9346H17.7842C15.8576 34.9346 14.2957 36.4964 14.2957 38.4231H36.5664Z" fill="white" stroke="#333333" strokeWidth="1.5" />
    <path d="M40.1836 18.3105L11.6726 18.3105" stroke="#333333" strokeWidth="1.5" />
    <circle cx="13.7938" cy="18.2061" r="2.5" transform="rotate(90 13.7938 18.2061)" fill="white" stroke="#333333" strokeWidth="1.5" />
    <path d="M40.1836 11.2514L11.6726 11.2514" stroke="#333333" strokeWidth="1.5" />
    <circle cx="13.7938" cy="11.147" r="2.5" transform="rotate(90 13.7938 11.147)" fill="white" stroke="#333333" strokeWidth="1.5" />
    <path d="M40.1836 25.3691L11.6726 25.3691" stroke="#333333" strokeWidth="1.5" />
    <circle cx="13.7938" cy="25.2646" r="2.5" transform="rotate(90 13.7938 25.2646)" fill="white" stroke="#333333" strokeWidth="1.5" />
  </svg>


);

module.exports = HomeHelpStatusImage;
