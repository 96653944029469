const React = require('react');

const Close = ({ className }) => (
  <svg
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="none"
    viewBox="0 0 16 16"
  >
    <path
      fill="#009EE3"
      d="M.94 2l6.003 6.003-6.004 6.004L2 15.067l6.004-6.003L14 15.061 15.06 14 9.065 8.003l5.997-5.996L14 .947 8.003 6.942 2 .939.94 2z"
    />
  </svg>
);

module.exports = Close;
