/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable nordic/i18n-jsx-no-literals */
/* eslint-disable react/jsx-no-target-blank */
const React = require('react');
const { getEventTracker } = require('../../../../../../utils/event-tracker');

const PaymentSolutions = ({
  image,
  title,
  description,
  link,
  linkDescription,
  cardFormat,
  titleLink,
  trackClickData,
}) => {
  const Tracker = getEventTracker();

  return (
    <div
      onClick={() => { if (link) window.location.assign(link); }}
      className={`payment-solutions__card ${
        cardFormat ? 'payment-solutions__card-format' : 'whithout-card-item'
      }`}
    >
      {image}
      <div className="payment-solutions__card--content">
        <h5>{title}</h5>
        <p>{description}</p>
        {linkDescription && (
          <a href={link} rel="noreferrer" target="_blank" title={titleLink} onClick={() => { Tracker.send(`mpdevelopers/click_${trackClickData}`); }}>
            {linkDescription}
          </a>
        )}
      </div>
    </div>
  );
};

module.exports = PaymentSolutions;
