/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');
const Style = require('frontend-style');
const Script = require('frontend-script');
const Head = require('react-declarative-head');
const PageContainer = require('../PageContainer');
const HomeHelp = require('./components/HomeHelp');
const HomePaymentSolutions = require('./components/HomePaymentSolutions');
const ProgrammingLanguageSupported = require('./components/ProgrammingLangSupported');
const HomeDevProgram = require('./components/HomeDevProgram');
const HomeHero = require('./components/HomeHero');

const Home = (props) => {
  const { deviceType, i18n, lang, platform, banners } = props;
  const isMobile = deviceType !== 'desktop';

  return (
    <PageContainer
      {...props}
      hideSearch
      hideSidebar
      hideSidebarEmpty
      state={props}
      metaDescription={i18n.gettext(
        'Implementa la solución de pagos ideal para cualquier tipo de negocio',
      )}
    >
      <Head />
      <title>{i18n.gettext('Mercado Pago Developers ')}</title>
      <Style href="home-styles.css" />
      <Style href="app-styles.css" />
      <Script src="home.js" preload priority={13} />
      <div className="home">
        <HomeHero i18n={i18n} lang={lang} isMobile={isMobile} banners={banners} siteId={platform?.siteId} />
        <HomePaymentSolutions {...props} />
        <ProgrammingLanguageSupported i18n={i18n} language={lang} />
        <HomeDevProgram
          i18n={i18n}
          isMobile={isMobile}
          lang={lang}
          siteId={platform?.siteId}
        />
        <HomeHelp {...props} />
      </div>
    </PageContainer>
  );
};

Home.propTypes = {
  platform: PropTypes.shape({
    siteId: PropTypes.string.isRequired,
  }).isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
  lang: PropTypes.string.isRequired,
  deviceType: PropTypes.string.isRequired,
};

/**
 * Inject i18n context as props into Hom.
 */
module.exports = Home;
