const React = require('react');
const ProgressIndicatorLinear = require('@andes/progress-indicator-linear');
const PropTypes = require('prop-types');
const { assistent } = require('../../../utils/search-client');
const { useEffect, useState, useRef } = React;

const Content = ({ term, lang, siteId, results, onProgressChange }) => {
  const defaultTitle = 'Resposta';
  const html = results?.llm?.html;
  const answerRef = useRef(null);
  const [progress, setProgress] = useState(0);
  const [title, setTitle] = useState(defaultTitle);

  const titlefy = (str) => {
    const fallback = defaultTitle;

    try {
      return str ? (str[0].toUpperCase() + str.substring(1)) : fallback;
    } catch {
      return fallback;
    }
  };

  const render = (content, md = false) => {
    const LLM = assistent({
      lang,
      site: siteId,
    });

    LLM.renderAnswerAI(content, {
      md,
      target: answerRef?.current,
      fader: {
        speed: 150,
        onProgress: setProgress,
      },
    });
  };

  useEffect(() => {
    render(html);
    setTitle(titlefy(term || results?.query?.text));
  }, []);

  useEffect(() => {
    if (onProgressChange) {
      onProgressChange(progress);
    }
  }, [progress]);

  return (
    <div className="search-result-ai__body">
      {progress < 100 && (
        <div className="progress-container">
          <ProgressIndicatorLinear
            srAnnouncement="Cargando..."
            srLabel="Progreso de subida"
            value={progress}
          />
        </div>
      )}

      {term.startsWith('__test:md') && (
        <textarea
          onBlur={e => render(e.target.value, true)}
          style={{ width: '100%', minHeight: '200px' }}
          placeholder="Markdown Debug Mode. Type something and perform a blur to test md capabilities."
        />
      )}

      <div className="search-result-ai__title">
        <h3>{title}</h3>
      </div>

      {/* the answer is dinamically filled */}
      <div ref={answerRef} className="search-result-ai__answer" />
    </div>
  );
};

Content.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
};

module.exports = Content;
