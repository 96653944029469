/* eslint-disable max-len */
/* eslint-disable security/detect-object-injection */
/* eslint-disable no-nested-ternary */
const pagesToHideSidebar = [
  '/docs/getting-started',
  '/docs/credentials',
  '/docs/testing/test-cards',
  '/docs/security/pci',
  '/docs/resources/legal/terms-and-conditions',
  '/docs/additional-content/reports/account-money/glossary',
  '/docs/additional-content/reports/released-money/glossary',
  '/docs/additional-content/reports/available-money/glossary',
  '/docs/payment-localization/consult-payment-methods',
  '/docs/testing/create-test-users',
  '/docs/additional-content/sales-processing/chargebacks',
  '/docs/sales-processing/payment-methods',
  '/docs/additional-content/integration-metrics',
  '/docs/homologator/integration-quality',
  '/docs/notifications/ipn/introduction',
  '/docs/certifications/your-certifications',
  '/docs/resources/legal/partners-privacy',
  '/docs/resources/legal/agencies-privacy',
  '/docs/resources/legal/platforms-privacy',
  '/docs/integration-quality',
];

class Menu {
  init(menuData, type) {
    this.menuData = this.menuData ? this.menuData : [];
    this.menuData[type] = menuData;
  }

  desestructureArrayItems(menuItems) {
    return menuItems?.flatMap((item) => {
      if (item?.childs) {
        const childs = item?.childs;
        return [{ ...item, childs: null }, ...this.desestructureArrayItems(childs)];
      }
      return item;
    });
  }

  getMenuDesestructuredArrayItems(siteId, lang, typeMenu = 'guides') {
    if (this.desestructuredMenu && this.desestructuredMenu?.length > 0) {
      return this.desestructuredMenu;
    }
    const menuItems = this.getMenuItems(siteId, lang, typeMenu, true);
    this.desestructuredMenu = this.desestructureArrayItems(menuItems);
    return this.desestructuredMenu;
  }

  getNearItem(menuItems, actualIndex, operator) {
    try {
      const newIndex = actualIndex + operator;
      const menuItem = { ...menuItems[newIndex] };
      if (menuItem.hidden || (!menuItem?.doc_path && operator < 0 && !menuItem?.is_last_article)) {
        return this.getNearItem(menuItems, newIndex, operator);
      }

      if (operator > 0 && !menuItem?.doc_path && !menuItem?.is_last_article) {
        const childItem = this.getNearItem(menuItems, newIndex, operator);
        menuItem.url = childItem?.url;
      }
      return menuItem;
    } catch {
      return null;
    }
  }

  getNextStepsMenuBased(siteId, lang, typeMenu = 'guides', url, isLastArticle, i18n) {
    const menuItems = this.getMenuDesestructuredArrayItems(siteId, lang, typeMenu);
    let itemIndex = 0;
    menuItems.forEach((item, index) => {
      if (item?.url === url) {
        itemIndex = index;
      }
    });
    const menuItem = menuItems[itemIndex];
    if (!menuItem) {
      return [null, null];
    }
    let previousItem = this.getNearItem(menuItems, itemIndex, -1);
    let nextItem = this.getNearItem(menuItems, itemIndex, +1);
    const isMiniLanding = (menuItem.order === 0 && menuItem.lvl === 1);
    if (previousItem?.is_last_article || previousItem?.id === menuItem?.id || isMiniLanding || this.verifyHiddenSidebarItem(url)) {
      previousItem = null;
    }
    if (isLastArticle || isMiniLanding || this.verifyHiddenSidebarItem(url)) {
      nextItem = null;
    }
    if (previousItem) {
      previousItem.isPrevious = true;
      previousItem.type = i18n.gettext('Anterior');
      previousItem.link = `/developers/${lang}${previousItem.url}`;
    }
    if (nextItem) {
      nextItem.isPrevious = false;
      nextItem.type = i18n.gettext('Próximo');
      nextItem.link = `/developers/${lang}${nextItem.url}`;
    }
    return [previousItem, nextItem];
  }

  verifyHiddenSidebarItem(url) {
    return pagesToHideSidebar.includes(url);
  }

  generateKey({ title, url, id }) {
    return `${id}--${title}--${url}`;
  }

  localizeWordings(item, siteId, lang, type) {
    const siteWording = item.content.find(
      (c) => c.site.toUpperCase() === siteId.toUpperCase() && c.lang.toUpperCase() === lang.toUpperCase(),
    );
    if (siteWording) {
      return siteWording[type];
    }
    const allWording = item.content.find(
      (c) => c.site.toUpperCase() === 'ALL' && c.lang.toUpperCase() === lang.toUpperCase(),
    );
    if (allWording) {
      return allWording[type];
    }
    return null;
  }

  filterMenuItems(arrayToFilter, siteId, typeMenu) {
    return arrayToFilter.filter((arrayItem) => {
      if (!arrayItem?.sites?.includes(siteId.toUpperCase()) || arrayItem?.status === 'removed') {
        return false;
      }
      if (arrayItem.childs && arrayItem.childs.length > 0) {
        arrayItem.childs = this.filterMenuItems(arrayItem.childs, siteId, typeMenu);
      }
      return true;
    });
  }

  parseChilds(itemOne, siteId, lang, lvl) {
    if (itemOne.childs && itemOne.childs.length > 0) {
      itemOne.childs.forEach((itemTwo) => {
        const itemOneCopy = { ...itemOne };
        delete itemOneCopy.childs;
        itemTwo.parentItem = itemOneCopy;
        itemTwo.title = this.localizeWordings(itemTwo, siteId, lang, 'title');
        itemTwo.key = this.generateKey(itemTwo);
        itemTwo.description = this.localizeWordings(itemTwo, siteId, lang, 'description');
        itemTwo.lvl = lvl;
        this.parseChilds(itemTwo, siteId, lang, lvl + 1);
      });
    }
  }

  getMenuItems(siteId, lang, typeMenu = 'guides', mustCompleteItemsData = true) {
    const lowerCaseSiteId = siteId.toLowerCase();
    const resultArray = this.filterMenuItems(this?.menuData[typeMenu] ? this.menuData[typeMenu] : [], lowerCaseSiteId, typeMenu);
    if (mustCompleteItemsData) {
      resultArray.forEach((itemArray) => {
        itemArray.key = this.generateKey(itemArray);
        itemArray.title = this.localizeWordings(itemArray, lowerCaseSiteId, lang, 'title');
        itemArray.description = this.localizeWordings(itemArray, lowerCaseSiteId, lang, 'description');
        itemArray.lvl = 0;
        this.parseChilds(itemArray, lowerCaseSiteId, lang, 1);
      });
    }
    return resultArray;
  }

  isURLOfChild(item) {
    const { url } = item;
    if (item.childs) {
      for (let i = 0; i < item.childs.length; i += 1) {
        if (item.childs[i].url === url) {
          return item.childs[i];
        }
      }
    }
    return item;
  }

  getItemChildFromURL(url, parentItem) {
    if (parentItem.childs && parentItem.childs.length > 0) {
      for (let k = 0; k < parentItem.childs.length; k += 1) {
        const itemTwo = parentItem.childs[k];
        const child = this.getItemChildFromURL(url, itemTwo);
        if (child) {
          return child;
        }
        if (itemTwo.url === url) {
          return itemTwo;
        }
      }
    }
    return null;
  }

  getItemFromURL(endpoint, siteId, lang, typeMenu, mustCompleteItemData) { // TODO : Mudar está lógica al backend. Que el request traiga el item de menú correspondiente a la URL.
    const url = endpoint.endsWith('/') ? endpoint.slice(0, -1) : endpoint;
    const menuItems = this.getMenuItems(siteId, lang, typeMenu, mustCompleteItemData);
    for (let j = 0; j < menuItems.length; j += 1) {
      const itemOne = menuItems[j];
      const child = this.getItemChildFromURL(url, itemOne);
      if (child) {
        return child;
      }
      if (itemOne.url === url) {
        return itemOne;
      }
    }
    return null;
  }

  getItemFromURLParent(endpoint, siteId, lang) {
    let itemActive = this.getItemFromURL(endpoint, siteId, lang);
    itemActive = itemActive?.lvl < 2 ? itemActive : itemActive?.parentItem;
    return itemActive;
  }

  isItemAvailable(requestedUrl, siteId, lang, type) {
    if (this.verifyHiddenSidebarItem(requestedUrl)) {
      return true;
    }
    const foundItem = this.getItemFromURL(requestedUrl, siteId, lang, type, false);
    return foundItem !== null;
  }

  getItemFromPathWithContext(context, destinyPath, type = 'guides', menu) {
    let menuToSearch = menu;
    if (!menuToSearch) {
      menuToSearch = this.menuData[type];
    }

    let resultItem = null;

    menuToSearch?.forEach((item) => {
      if (resultItem) {
        return;
      }
      if (item.doc_path === destinyPath && item?.url?.startsWith(`/docs/${context}`)) {
        resultItem = item;
        return;
      }
      if (item?.childs && item?.childs?.length > 0) {
        resultItem = this.getItemFromPathWithContext(context, destinyPath, type, item?.childs);
      }
    });
    return resultItem;
  }
}

module.exports = new Menu();
