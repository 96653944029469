const React = require('react');

const ExternalLink = ({ className }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
    <g fill="none" fillOpacity=".45" fillRule="evenodd">
      <g fill="#000" fillRule="nonzero">
        <g>
          <g>
            <g>
              <g>
                <path d="M4.818 1.718l.014 1.2-3.093.033.021 7.505 8.14-.035V7.518h1.2v4.098l-10.537.046-.027-9.896 4.282-.048zM11.79.4v5.4h-1.2V2.51L5.725 7.6l-.867-.828L9.798 1.6H6.39V.4h5.4z" transform="translate(-120 -270) translate(35 260) translate(85 10) translate(1 1)" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>

);

module.exports = ExternalLink;
