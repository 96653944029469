/* eslint-disable react/prop-types */
const React = require('react');
const InfoIcon = require('@andes/icons/Info16');

const SearchResultEmptyState = ({ i18n }) => (
  <div>
    <div className="result-list-empty-state">
      <div className="result-title">
        <h3>{i18n.gettext('No encontramos resultados para esta búsqueda')}</h3>
      </div>

      <div className="empty-state-message">
        <InfoIcon className="info-icon" width="15.6px" height="15.6px" />

        <p>
          {i18n.gettext('Revisa la ortografía de la palabra.')}<br />
          {i18n.gettext('Utiliza palabras más genéricas o menos palabras.')}
        </p>
      </div>
    </div>
  </div>
);

module.exports = SearchResultEmptyState;
