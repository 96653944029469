const React = require('react');

function FaceToFacePaymentImage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 101 100"
    >
      <g>
        <g>
          <g>
            <g>
              <path
                fill="#fff"
                stroke="#333"
                strokeWidth="1.5"
                d="M19.286 39.269H88.569V80.024H19.286z"
              />
              <g>
                <rect
                  width="23.442"
                  height="16.578"
                  x="31.605"
                  y="53.032"
                  fill="#fff"
                  stroke="#333"
                  strokeWidth="1.5"
                  rx="1"
                />
                <path
                  stroke="#333"
                  strokeWidth="1.5"
                  d="M48.201 63.537l4.04-3.617"
                />
                <path
                  stroke="#333"
                  strokeWidth="1.5"
                  d="M34.69 62.478l4.04-3.618"
                />
                <path
                  stroke="#333"
                  strokeWidth="1.5"
                  d="M47.267 60.817l2.436-2.038"
                />
                <path
                  stroke="#333"
                  strokeWidth="1.5"
                  d="M36.816 64.367l2.437-2.038"
                />
                <g>
                  <path
                    fill="#fff"
                    fillRule="evenodd"
                    d="M43.327 69.61V53.032 69.61z"
                    clipRule="evenodd"
                  />
                  <path
                    stroke="#333"
                    strokeWidth="1.5"
                    d="M43.327 69.61V53.032"
                  />
                </g>
              </g>
              <g>
                <g>
                  <mask id="path-9-inside-1_986_171351" fill="#fff">
                    <path
                      fillRule="evenodd"
                      d="M21.33 44.337c4.4 0 7.967-2.767 7.967-6.181l.136-7.909H13.5l-.135 7.909c0 3.414 3.566 6.181 7.966 6.181zm42.851 7.104a1 1 0 011-1h17.535a1 1 0 011 1v28.582H64.18V51.441zm-41.346 8.205l2.768 15.752H14.18l-2.835-15.752h11.49zm38.597-21.49c0 3.414-3.566 6.181-7.966 6.181S45.5 41.57 45.5 38.156l.136-7.909h15.932l-.136 7.909zm24.17 6.181c4.4 0 7.966-2.767 7.966-6.181l.135-7.909H77.771l-.136 7.909c0 3.414 3.567 6.181 7.967 6.181z"
                      clipRule="evenodd"
                    />
                  </mask>
                  <path
                    fill="#5AD2FF"
                    fillRule="evenodd"
                    d="M21.33 44.337c4.4 0 7.967-2.767 7.967-6.181l.136-7.909H13.5l-.135 7.909c0 3.414 3.566 6.181 7.966 6.181zm42.851 7.104a1 1 0 011-1h17.535a1 1 0 011 1v28.582H64.18V51.441zm-41.346 8.205l2.768 15.752H14.18l-2.835-15.752h11.49zm38.597-21.49c0 3.414-3.566 6.181-7.966 6.181S45.5 41.57 45.5 38.156l.136-7.909h15.932l-.136 7.909zm24.17 6.181c4.4 0 7.966-2.767 7.966-6.181l.135-7.909H77.771l-.136 7.909c0 3.414 3.567 6.181 7.967 6.181z"
                    clipRule="evenodd"
                  />
                  <path
                    fill="#fff"
                    d="M29.297 38.156l-3-.051V38.156h3zm.136-7.909l2.999.052.052-3.052h-3.052v3zm-15.933 0v-3h-2.949l-.05 2.95 3 .05zm-.135 7.909l-3-.051V38.156h3zm70.35 41.867v3h3v-3h-3zm-19.534 0h-3v3h3v-3zm-38.578-4.625v3h3.574l-.619-3.52-2.955.52zm-2.768-15.752l2.954-.52-.436-2.48h-2.518v3zM14.18 75.398l-2.953.531.445 2.469h2.508v-3zm-2.835-15.752v-3H7.757l.635 3.532 2.953-.532zm50.087-21.49l-3-.051V38.156h3zm-15.932 0l-3-.051V38.156h3zm.136-7.909v-3h-2.95l-.05 2.95 3 .05zm15.932 0l3 .052.052-3.052h-3.052v3zm32 7.909l-3-.051V38.156h3zm.135-7.909l3 .052.052-3.052h-3.052v3zm-15.932 0v-3h-2.95l-.05 2.95 3 .05zm-.136 7.909l-3-.051V38.156h3zm-51.338 0c0 1.093-1.47 3.181-4.966 3.181v6c5.304 0 10.966-3.447 10.966-9.181h-6zm.136-7.96l-.135 7.909 5.999.102.135-7.908-6-.103zM13.5 33.248h15.933v-6H13.5v6zm2.864 4.96l.136-7.91-6-.102-.135 7.909 6 .102zm4.967 3.129c-3.495 0-4.966-2.088-4.966-3.181h-6c0 5.734 5.662 9.181 10.966 9.181v-6zm43.85 6.104a4 4 0 00-4 4h6a2 2 0 01-2 2v-6zm17.535 0H65.18v6h17.535v-6zm4 4a4 4 0 00-4-4v6a2 2 0 01-2-2h6zm0 28.582V51.441h-6v28.582h6zm-22.535 3h19.535v-6H64.18v6zm-3-31.582v28.582h6V51.441h-6zM28.558 74.878L25.79 59.127l-5.91 1.038 2.77 15.752 5.91-1.039zm-14.378 3.52h11.423v-6H14.18v6zm-5.788-18.22l2.835 15.751 5.905-1.063-2.835-15.751-5.905 1.063zm14.443-3.532h-11.49v6h11.49v-6zm30.631-9.309c5.304 0 10.967-3.447 10.967-9.181h-6c0 1.093-1.471 3.181-4.967 3.181v6zM42.5 38.156c0 5.734 5.663 9.181 10.966 9.181v-6c-3.495 0-4.966-2.088-4.966-3.181h-6zm.136-7.96l-.135 7.909 5.999.102.135-7.908-6-.103zm18.932-2.949H45.636v6h15.932v-6zm2.864 10.96l.135-7.908-5.999-.103-.135 7.909 5.999.102zm26.136-.051c0 1.093-1.471 3.181-4.966 3.181v6c5.303 0 10.966-3.447 10.966-9.181h-6zm.136-7.96l-.136 7.909 6 .102.135-7.908-6-.103zM77.77 33.248h15.932v-6H77.771v6zm2.864 4.96l.135-7.91-5.999-.102-.135 7.909 6 .102zm4.967 3.129c-3.496 0-4.967-2.088-4.967-3.181h-6c0 5.734 5.663 9.181 10.967 9.181v-6z"
                    mask="url(#path-9-inside-1_986_171351)"
                  />
                </g>
                <path
                  stroke="#333"
                  strokeWidth="1.5"
                  d="M64.18 52.44a2 2 0 012-2h15.536a2 2 0 012 2v27.584H64.18V52.44z"
                />
                <circle
                  cx="73.949"
                  cy="58.675"
                  r="2.871"
                  fill="#fff"
                  stroke="#333"
                  strokeWidth="1.5"
                />
              </g>
              <g stroke="#333" strokeWidth="1.5">
                <path d="M7.286 79.949l3.506-20.303h11.49" />
                <path d="M26.34 79.949l-3.505-20.303h-11.49L14.85 79.95" />
                <path
                  fillRule="evenodd"
                  d="M25.603 75.398l-2.768-15.752h-11.49l2.769 15.752h11.49z"
                  clipRule="evenodd"
                />
              </g>
              <g
                fillRule="evenodd"
                stroke="#333"
                strokeWidth="1.5"
                clipRule="evenodd"
              >
                <path
                  fill="#fff"
                  d="M45.365 38.156c0 3.414-3.567 6.181-7.966 6.181-4.4 0-7.966-2.767-7.966-6.181l.135-7.909H45.5l-.135 7.909z"
                />
                <path
                  fill="#fff"
                  d="M77.5 38.156c0 3.414-3.567 6.181-7.966 6.181-4.4 0-7.967-2.767-7.967-6.181l.136-7.909h15.932l-.135 7.909z"
                />
                <path d="M29.297 38.156c0 3.414-3.567 6.181-7.967 6.181-4.4 0-7.966-2.767-7.966-6.181l.136-7.909h15.932l-.135 7.909z" />
                <path d="M61.432 38.156c0 3.414-3.566 6.181-7.966 6.181S45.5 41.57 45.5 38.156l.135-7.909h15.933l-.136 7.909z" />
                <path d="M93.568 38.156c0 3.414-3.567 6.181-7.966 6.181-4.4 0-7.966-2.767-7.966-6.181l.135-7.909h15.932l-.135 7.909z" />
                <path
                  fill="#fff"
                  d="M13.292 30.008l.052-.062c.75-.89 6.839-10.406 7.209-10.86a3.259 3.259 0 012.526-1.188h60.676c.987 0 1.912.492 2.484 1.32 0 0 7.928 10.622 7.435 10.772-.492.15-80.574.254-80.382.018z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

module.exports = FaceToFacePaymentImage;
