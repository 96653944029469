/* eslint-disable max-len */
const React = require('react');

const CertificationBlueImage = () => (
  <svg width="49" height="36" viewBox="0 0 49 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="6" width="37" height="36" rx="9" fill="#5AD2FF" />
    <rect x="6" width="37" height="36" rx="9" fill="black" fillOpacity="0.3" />
    <rect x="6" width="37" height="33" rx="9" fill="#5AD2FF" />
    <rect x="6.75" y="0.75" width="35.5" height="34.5" rx="8.25" stroke="#333333" strokeWidth="1.5" />
    <path d="M2.37667 15.8978C1.16944 11.0689 4.82172 6.39111 9.79925 6.39111H39.2007C44.1783 6.39111 47.8306 11.0689 46.6233 15.8978C46.3187 17.1161 46.3187 18.3907 46.6233 19.6091C47.8306 24.438 44.1783 29.1158 39.2007 29.1158H9.79925C4.82172 29.1158 1.16944 24.438 2.37667 19.6091C2.68126 18.3907 2.68126 17.1161 2.37667 15.8978Z" fill="white" />
    <path d="M2.59141 13.6644C1.76303 10.3509 4.26916 7.14111 7.68466 7.14111H41.3153C44.7308 7.14111 47.237 10.3509 46.4086 13.6644L45.7956 16.1163C45.5269 17.1912 45.5269 18.3157 45.7956 19.3905L46.4086 21.8424C47.237 25.156 44.7308 28.3658 41.3153 28.3658H7.68466C4.26916 28.3658 1.76303 25.156 2.59141 21.8424L3.20438 19.3905C3.4731 18.3157 3.4731 17.1912 3.20438 16.1163L2.59141 13.6644Z" stroke="#333333" strokeWidth="1.5" />
    <rect x="9" y="15" width="31" height="6" rx="3" fill="#3F93B3" />
  </svg>

);

module.exports = CertificationBlueImage;
