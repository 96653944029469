/* eslint-disable max-len */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
const React = require('react');
const Head = require('react-declarative-head');
const I18n = require('nordic/i18n');
const injectI18n = require('nordic/i18n/injectI18n');
const Script = require('nordic/script');
const serialize = require('serialize-javascript');
const { sanitizePath } = require('../../common/URLSanitizer');
const MenuUtils = require('../../../utils/menu');
const Sidebar = require('../Sidebar');
const SidebarEmpty = require('../Sidebar/SidebarEmpty');
const isMobile = require('../../../node_modules/ismobilejs');
const ConfigureGTM = require('../../../utils/configure-gtm');
const { configureEventTracker, dxGTMScriptSource, dxGAScriptSnippet } = require('../../../utils/event-tracker');
const { MelidataTrack } = require('frontend-melidata/melidata-track');
const { useEffect } = React;
const getTitle = (item) => `${item.title + (item.parentItem ? ` - ${item.parentItem.title}` : '')} - Mercado Pago Developers`;

const getMetaDescription = (item) => {
  if (!item) {
    return null;
  }
  const description = item.description ? item.description : item.title;
  const parentDescription = item.parentItem ? ` - ${item.parentItem.title}` : '';
  return description + parentDescription;
};

const getAlternateUrls = (url) => {
  const alternateCountries = [
    { id: 'AR', domain: '.com.ar' },
    { id: 'BR', domain: '.com.br' },
    { id: 'MX', domain: '.com.mx' },
    { id: 'CL', domain: '.cl' },
    { id: 'CO', domain: '.com.co' },
    { id: 'PE', domain: '.com.pe' },
    { id: 'UY', domain: '.com.uy' },
  ];
  const alternateLangs = ['es', 'pt', 'en'];

  return alternateCountries.map(alternateCountry => (
    alternateLangs.map(alternateLang => (
      <link
        rel="alternate"
        hrefLang={`${alternateLang}-${alternateCountry.id}`}
        href={url.replace(/(\.com\.[a-z]{2}|\.cl)/, alternateCountry.domain).replace(/\/(es|pt|en)\//, `/${alternateLang}/`)}
      />
    ))
  ));
};

const PageContainer = (props) => {
  const { actualUrl, children, translations = [], deviceType, platform, setHtmlContent, lang, history, menuItems, activeItem, setActiveItem, hideSidebar, showSidebarEmpty, type = 'guides', metaDescription, userData, state } = props;
  const { melidataEventData } = props;
  const i18n = new I18n({
    translations,
  });

  const { host, path } = actualUrl;
  const normalizedPath = path.endsWith('/') ? path.slice(0, -1) : path;
  const canonicalUrl = host + normalizedPath;
  let urlItem;

  if (!hideSidebar) {
    urlItem = MenuUtils.getItemFromURL(sanitizePath(normalizedPath), platform.siteId, lang, type);
  }

  const isNonEmptyObject = (obj) => {
    try {
      return obj && Object.keys(obj).length > 0;
    } catch (error) {
      console.error('An error occurred:', error);
      return false;
    }
  };

  useEffect(() => {
    configureEventTracker({
      lang,
      isMobile: isMobile.any,
      siteId: platform.siteId,
      isLogged: (userData && userData.id !== null && userData.id !== undefined),
    });
  }, []);

  const metaDescriptionContent = isNonEmptyObject(metaDescription) ? metaDescription : getMetaDescription(urlItem);
  return (
    <div>
      <Head>
        <link rel="canonical" href={canonicalUrl} />
        {getAlternateUrls(canonicalUrl)}
        {metaDescriptionContent && (<meta name="description" content={metaDescriptionContent} />)}
        {urlItem && urlItem.no_index && <meta name="robots" content="noindex" />}
        <ConfigureGTM />
        <Script priority={1} id="dx-gtm-script-source" src={dxGTMScriptSource()} />
        <Script priority={2} id="dx-ga-script-snippet" inline>{dxGAScriptSnippet()}</Script>
      </Head>
      <Script priority={12}>
        {`
            window.__PRELOADED_STATE__ = ${serialize(state, { isJSON: true })};
        `}
      </Script>
      {melidataEventData && (
        <MelidataTrack path={melidataEventData?.path} event_data={melidataEventData?.eventData} />
      )}
      {urlItem && (<title>{getTitle(urlItem)}</title>)}
      <label
        className="dev-main__overlay slideout-dimmer slideout-panel slideout-panel-left"
        htmlFor="drawer-trigger"
        role="main"
      />
      { !hideSidebar && (
        <Sidebar
          actualPath={normalizedPath}
          i18n={i18n}
          setHtmlContent={setHtmlContent}
          lang={lang}
          deviceType={deviceType}
          history={history}
          activeItem={activeItem}
          setActiveItem={setActiveItem}
          menuItems={menuItems}
          siteId={platform.siteId}
        />
      )}

      <div className={`main-page-container ${!hideSidebar ? 'main-page-container--with-sidebar' : ''}`}>
        {showSidebarEmpty && (<SidebarEmpty />)}
        {children}
      </div>
    </div>
  );
};

PageContainer.defaultProps = {
  hideSearch: false,
  hideSidebar: false,
  showEmpty: false,
};

module.exports = injectI18n(PageContainer);
