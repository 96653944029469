const React = require('react');

function WalletImage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 102 100"
    >
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M53.886 52.21L24.202 81.896c-.95.95-2.5.94-3.464-.023L2.89 64.022c-.963-.963-.973-2.513-.024-3.463l29.685-29.685c.95-.95 2.5-.939 3.463.024l17.849 17.848c.963.963.973 2.514.023 3.464z"
        clipRule="evenodd"
      />
      <path
        fill="#EEE"
        fillRule="evenodd"
        d="M19.68 80.813c.79.79 2.435.428 3.703-.8l.06-.06L51.957 51.44c1.256-1.256 1.648-2.908.895-3.723l-.036-.038 1.056 1.056c.98.98.739 2.807-.537 4.083L24.82 81.332c-1.276 1.275-3.104 1.517-4.083.537l-1.056-1.056z"
        clipRule="evenodd"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path
        stroke="#333"
        strokeWidth="1.5"
        d="M53.886 52.21L24.202 81.896c-.95.95-2.5.94-3.464-.023L2.89 64.022c-.963-.963-.973-2.513-.024-3.463l29.685-29.685c.95-.95 2.5-.939 3.463.024l17.849 17.848c.963.963.973 2.514.023 3.464"
      />
      <rect
        width="7.517"
        height="5.136"
        x="19.515"
        y="73.448"
        stroke="#333"
        strokeWidth="1.5"
        rx="2"
        transform="rotate(-45 19.515 73.448)"
      />
      <path
        fill="#5AD2FF"
        stroke="#fff"
        strokeWidth="3"
        d="M8.747 67.759l-2.084-2.084 31.003-31.003 2.084 2.084L8.747 67.759z"
      />
      <path
        fillRule="evenodd"
        stroke="#333"
        strokeWidth="1.5"
        d="M37.666 32.55L4.542 65.676l4.205 4.205 33.124-33.124-4.205-4.205z"
        clipRule="evenodd"
      />
      <path
        stroke="#333"
        strokeWidth="1.5"
        d="M16.14 70.186L29.125 57.2M31.142 55.184l3.295-3.295"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        stroke="#333"
        strokeWidth="1.5"
        d="M37.561 28.914H85.25a7.566 7.566 0 017.566 7.566v29.575a7.566 7.566 0 01-7.566 7.566H37.561c-.76 0-1.376-.616-1.376-1.375V30.289c0-.76.616-1.375 1.376-1.375z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        stroke="#333"
        strokeWidth="1.5"
        d="M39.776 47.621l-2.428-2.182a1.564 1.564 0 01-.114-2.212l22.14-24.55a1.57 1.57 0 012.214-.118l14.747 13.258c.642.578.694 1.568.114 2.21l-22.14 24.551a1.57 1.57 0 01-2.214.118l-10.67-9.592-1.649-1.483z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        stroke="#333"
        strokeWidth="1.5"
        d="M45.024 40.444l-1.605-3.284a1.75 1.75 0 01.808-2.342l33.27-16.196a1.757 1.757 0 012.347.807L89.59 39.37a1.75 1.75 0 01-.809 2.343L55.511 57.907a1.757 1.757 0 01-2.347-.806l-7.05-14.428-1.09-2.23z"
        clipRule="evenodd"
      />
      <path
        fill="#5AD2FF"
        stroke="#fff"
        strokeWidth="3"
        d="M37.685 81.146V36.275H88.72a6.601 6.601 0 016.602 6.601v31.668a6.601 6.601 0 01-6.602 6.602H37.686z"
      />
      <path
        fillRule="evenodd"
        stroke="#333"
        strokeWidth="1.5"
        d="M37.658 34.775h51.063a8.101 8.101 0 018.1 8.101v31.668a8.101 8.101 0 01-8.1 8.102H37.657c-.813 0-1.473-.66-1.473-1.473V36.248c0-.814.66-1.473 1.473-1.473z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M50.855 59.187c0-5.83 4.726-10.555 10.556-10.555h38.929v21.11H61.41c-5.829 0-10.554-4.726-10.554-10.555z"
        clipRule="evenodd"
      />
      <path
        fill="#EEE"
        fillRule="evenodd"
        d="M51 57.437c-.095.57-.145 1.154-.145 1.75 0 5.83 4.726 10.555 10.556 10.555h38.929v-3.5H61.41c-5.233 0-9.577-3.808-10.41-8.805z"
        clipRule="evenodd"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path
        fillRule="evenodd"
        stroke="#333"
        strokeWidth="1.5"
        d="M50.855 59.187c0-5.83 4.726-10.555 10.556-10.555h38.929v21.11H61.41c-5.829 0-10.554-4.726-10.554-10.555v0z"
        clipRule="evenodd"
      />
      <ellipse
        cx="62.344"
        cy="59.187"
        fill="#fff"
        stroke="#333"
        strokeWidth="1.5"
        rx="4.861"
        ry="4.861"
      />
      <ellipse
        cx="75.829"
        cy="26.664"
        stroke="#333"
        rx="4.193"
        ry="4.134"
        transform="rotate(-25.468 75.829 26.664)"
      />
      <path
        stroke="#333"
        d="M75.04 24.866l-.31-.654M76.987 28.952l-.311-.654M76.32 24.915c-.306-.261-.738-.307-1.322-.028-.613.292-.905.85-.667 1.35.295.62.726.569 1.36.449l.12-.022.08-.014c.515-.089 1.143-.236 1.43.238.287.474.1 1.107-.71 1.49-.573.271-1.183.235-1.47-.143"
      />
      <path
        fillRule="evenodd"
        stroke="#333"
        strokeWidth="0.8"
        d="M3.125 31.715a.5.5 0 00.5.5H7.8a.5.5 0 00.5-.5V27.49a.5.5 0 00-.5-.5H3.625a.5.5 0 00-.5.5v4.225z"
        clipRule="evenodd"
      />
      <path
        stroke="#333"
        strokeWidth="0.8"
        d="M5.233 24.946H1.265a.12.12 0 00-.119.12v4.006M5.233 42.052H1.265a.12.12 0 01-.119-.12v-4.006M13.649 24.946h3.967a.12.12 0 01.12.12v4.006M13.649 42.052h3.967a.12.12 0 00.12-.12v-4.006"
      />
      <path
        fillRule="evenodd"
        stroke="#333"
        strokeWidth="0.8"
        d="M10.767 31.715a.5.5 0 00.5.5h4.175a.5.5 0 00.5-.5V27.49a.5.5 0 00-.5-.5h-4.175a.5.5 0 00-.5.5v4.225zM3.125 39.49a.5.5 0 00.5.5H7.8a.5.5 0 00.5-.5v-4.225a.5.5 0 00-.5-.5H3.625a.5.5 0 00-.5.5v4.224z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        stroke="#333"
        strokeWidth="0.8"
        d="M14.356 30.133a.48.48 0 01-.48.48v0h-1.024a.5.5 0 01-.5-.5v-1.022a.5.5 0 01.5-.5h1.004a.5.5 0 01.5.5v1.042zM6.714 30.133a.48.48 0 01-.48.48v0H5.21a.5.5 0 01-.5-.5v-1.022a.5.5 0 01.5-.5h1.003a.5.5 0 01.5.5v1.042zM6.714 37.908a.48.48 0 01-.48.48v0H5.21a.5.5 0 01-.5-.5v-1.022a.5.5 0 01.5-.5h1.003a.5.5 0 01.5.5v1.042zM13.354 36.877a.5.5 0 01-.5.5H11.267a.5.5 0 01-.5-.5v-1.612a.5.5 0 01.5-.5h1.587a.5.5 0 01.5.5v1.612zM15.942 39.49a.5.5 0 01-.5.5H13.855a.5.5 0 01-.5-.5v-1.613a.5.5 0 01.5-.5h1.587a.5.5 0 01.5.5v1.612z"
        clipRule="evenodd"
      />
    </svg>
  );
}

module.exports = WalletImage;
