const React = require('react');

const Search = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    fill="none"
    viewBox="0 0 15 15"
  >
    <path
      fill="#009EE3"
      fillRule="evenodd"
      d="M9.998 10.846a6 6 0 11.849-.848l4 4-.848.85-4.001-4.002zM1.4 6.2a4.8 4.8 0 109.6 0 4.8 4.8 0 00-9.6 0z"
      clipRule="evenodd"
    />
  </svg>
);

module.exports = Search;
