/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-target-blank */
const React = require('react');
const CarouselSnapped = require('@andes/carousel-snapped');
const { getEventTracker } = require('../../../../../utils/event-tracker');

const { CarouselSnappedSlide } = CarouselSnapped;

const HomeDevProgramCarouselDesktopStandar = ({ children, certifications, title }) => (
  <div className={certifications <= 3 ? 'home-dev-program__carousel-desktop-standard' : 'home-dev-program__carousel-desktop-expaned'}>
    <h1 className="home-dev-program__carousel-title">{title}</h1>
    <CarouselSnapped className={certifications <= 3 ? 'home-dev-program__carousel-desktop-standard-wrapper' : 'home-dev-program__carousel-desktop-expaned-wrapper'} type="content" arrows={certifications > 3} pagination={certifications > 3} scroll="hidden" slidesPerView={3}>
      {children}
    </CarouselSnapped>
  </div>
);

HomeDevProgramCarouselDesktopStandar.CarouselItemDesktopStandar = ({
  title,
  index,
  subtitle,
  url,
  image,
  linkDescription,
  certifications,
  tracking,
  label,
}) => {
  const Tracker = getEventTracker();

  return (
    <CarouselSnappedSlide
      className={certifications > 3 ? 'desktop-carrousel' : 'desktop-simple'}
      index={index}
      key={`carousel__slide ${index}`}
    >
      <a href={url} target="_self" style={{ display: 'contents' }}>
        <div className="wrapper">
          {image}
          <h1 className="title">{title}</h1>
          <p className="subtitle">{subtitle}</p>

          <a
            href={url}
            target="_self"
            title={label}
            onClick={() => { Tracker.send(`mpdevelopers/click_${tracking}`); }}
          >
            {linkDescription}
          </a>
        </div>
      </a>
    </CarouselSnappedSlide>
  );
};

module.exports = HomeDevProgramCarouselDesktopStandar;
