/* eslint-disable max-len */
const React = require('react');

const HomeHelpSupportImage = () => (
  <svg className="home-help--image" width="52" height="43" viewBox="0 0 52 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.02531 31.0964L10.5253 31.0964V32.5964V38.7841L23.0997 31.3071L23.454 31.0964H23.8663H47.5957C48.6518 31.0964 49.5 30.2442 49.5 29.2027V3.89371C49.5 2.85225 48.6518 2 47.5957 2H4.65164C3.59554 2 2.74735 2.85225 2.74733 3.89371L2.74654 29.2027C2.74654 30.2442 3.59468 31.0965 4.65088 31.0965L9.02531 31.0964Z" fill="#5AD2FF" stroke="white" strokeWidth="3" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.40431 0.826172C2.52419 0.826172 1.00004 2.34559 1 4.21985L1.24654 29.2025C1.24654 31.0768 2.77064 32.5962 4.65085 32.5962L9.02532 32.5962V41.421L23.8663 32.5962H47.5957C49.4758 32.5962 51 31.0768 51 29.2025L50.7527 4.21988C50.7527 2.34559 49.2285 0.826172 47.3484 0.826172H4.40431Z" stroke="#333333" strokeWidth="1.5" />
  </svg>
);

module.exports = HomeHelpSupportImage;
