const React = require('react');

function OnboardingModalImage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="213"
      height="142"
      fill="none"
      viewBox="0 0 213 142"
    >
      <path
        fill="#5AD2FF"
        stroke="#fff"
        strokeWidth="3"
        d="M31.5 12.5H197.5V106.5H31.5z"
      />
      <path
        stroke="#333"
        strokeWidth="1.5"
        d="M30.353 10.77H198.651V107.533H30.353z"
      />
      <path
        fill="#fff"
        stroke="#333"
        strokeWidth="1.5"
        d="M30.353 7.249a6 6 0 016-6h156.329a6 6 0 016 6v3.84H30.353V7.25z"
      />
      <path
        fill="#fff"
        d="M16.923 107.712c0 5.929 4.806 10.736 10.736 10.736h173.717c5.93 0 10.736-4.807 10.736-10.736H16.922z"
      />
      <path
        fill="#EEE"
        fillRule="evenodd"
        d="M28.1 115.415h172.837c4.787 0 8.836-2.577 10.179-6.123h.473l-.004.239c-.155 4.691-4.863 8.454-10.648 8.454H28.101c-5.883 0-10.652-3.892-10.652-8.693h.473c1.343 3.546 5.392 6.123 10.179 6.123z"
        clipRule="evenodd"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path
        stroke="#333"
        strokeWidth="1.5"
        d="M16.923 107.712c0 5.929 4.806 10.736 10.736 10.736h173.717c5.93 0 10.736-4.807 10.736-10.736H16.922z"
      />
      <path
        fillRule="evenodd"
        stroke="#333"
        strokeWidth="1.5"
        d="M96.035 107.712a3.58 3.58 0 003.581 3.578h29.802a3.58 3.58 0 003.582-3.578H96.035z"
        clipRule="evenodd"
      />
      <ellipse cx="115" cy="6.635" fill="#333" rx="1.501" ry="1.5" />
      <path
        stroke="#333"
        strokeWidth="1.5"
        d="M92.21 37.005H137.776V82.287H92.21z"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        stroke="#333"
        strokeWidth="1.5"
        d="M114.748 70.934l16.042-20.047 7.055 8.816-.064 22.59h-31.609l8.576-11.36z"
        clipRule="evenodd"
      />
      <ellipse
        cx="111.685"
        cy="46.862"
        fill="#fff"
        stroke="#333"
        strokeWidth="1.5"
        rx="5.513"
        ry="5.479"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        stroke="#333"
        strokeWidth="1.5"
        d="M92.205 72.065l11.754-14.238 19.56 24.442-31.314.023V72.065z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M58.675 30.756s7.46-6.53 10.202-6.423c2.743.109 3.682 4.115 2.023 6.93-1.662 2.815-11.117 8.95-11.117 8.95l-1.108-9.457z"
        clipRule="evenodd"
      />
      <path
        fill="#EEE"
        fillRule="evenodd"
        d="M56.887 30.76s1.848-.996 4.02-1.99v11.002c-1.34.927-2.303 1.553-2.303 1.553l-4.094 1.717-1.98-4.094-.661-4.358 5.018-3.83z"
        clipRule="evenodd"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path
        fillRule="evenodd"
        stroke="#333"
        strokeWidth="1.5"
        d="M58.675 30.198s6.07-5.367 9.559-5.832c3.488-.464 5.062 3.83 3.068 6.331-1.994 2.501-11.519 9.423-11.519 9.423l-4.475 1.97-2.165-4.696-.723-4.998 6.255-2.198z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M6.547 86.183s-2.032-26.665-.646-29.438c.782-1.562 3.983-10.566 6.14-18.619 1.675-6.245 2.207-12.108 3.764-12.28 1.169-.13 2.164.471 2.971 1.446v20.346c-.348 1.536-.594 2.57-.594 2.57l.594.453.077 35.708c0 2.76 2.693 5.078 5.454 5.078 0 0 27.804-1.561 26.323 0-.701.74-4.856 5.111-5.484 5.66C41.977 99.88 39 100.88 39 100.88v16c0 7 1 20 1 20l-39 4 5.547-54.697z"
        clipRule="evenodd"
      />
      <path
        fill="#EEE"
        fillRule="evenodd"
        d="M19.296 92.109v-5.018c0 2.761 2.345 5 5.24 5h24.936c-1.552 1.561-3.186 3.14-4.437 4.185-.305.254-.657.535-1.045.832H24.535c-2.894 0-5.239-2.238-5.239-4.999z"
        clipRule="evenodd"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path
        stroke="#333"
        strokeWidth="1.5"
        d="M.885 141.149s5.463-55.587 5.463-60.118c0-3.89-.768-11.361-.768-11.361-.116-2.945-.313-4.032-.156-8.093.156-4.062 1.227-6.6 1.862-8.355 1.343-3.717 3.29-9.626 4.756-15.096 1.674-6.245 2.206-12.109 3.763-12.281 1.169-.13 2.164.472 2.971 1.448v20.344c-.348 1.537-.594 2.571-.594 2.571l.594.453"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M58.957 72.67V24.62c0-2.275-1.882-4.12-4.202-4.12H23.909c-2.32 0-4.203 1.845-4.203 4.12V86.588c0 2.688 2.223 4.867 4.966 4.867h29.32c2.742 0 4.965-2.18 4.965-4.867V72.67z"
        clipRule="evenodd"
      />
      <path
        fill="#5AD2FF"
        stroke="#fff"
        strokeWidth="3"
        d="M21.172 29.8H57.42400000000001V81.148H21.172z"
      />
      <path
        fill="#EEE"
        fillRule="evenodd"
        d="M53.772 89.178H24.64c-2.725 0-4.934-2.114-4.934-4.723v2.276c0 2.61 2.209 4.724 4.934 4.724h29.132c2.725 0 4.934-2.115 4.934-4.724v-2.276c0 2.609-2.21 4.723-4.934 4.723z"
        clipRule="evenodd"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path
        fillRule="evenodd"
        stroke="#333"
        strokeWidth="1.5"
        d="M58.924 72.67V24.62c0-2.275-1.882-4.12-4.203-4.12H23.876c-2.321 0-4.204 1.845-4.204 4.12V86.588c0 2.688 2.224 4.867 4.967 4.867h29.319c2.743 0 4.966-2.18 4.966-4.867V72.67z"
        clipRule="evenodd"
      />
      <path
        stroke="#333"
        strokeWidth="1.5"
        d="M19.706 82.648h39M58.706 28.3h-39M37.675 24.557h5.785M35.356 24.557h1.045M36.312 86.674h5.032"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M18.546 51.352s2.937-12.234 3.147-15.76c.21-3.526-1.888-10.161-5.665-9.746-1.65.18-1.584 4.453-3.357 10.99l1.178 14.516"
        clipRule="evenodd"
      />
      <path
        stroke="#333"
        strokeWidth="1.5"
        d="M18.546 51.352s2.937-12.234 3.147-15.76c.21-3.526-1.888-10.161-5.665-9.746-1.65.18-2.213 6.318-3.986 12.856M49.858 91.447c-1.031 1.911-3.287 5.471-10.241 8.636-.855.389-1.07 1.086-1.07 5.348 0 6.109 1.07 31.412 1.07 31.412"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        stroke="#333"
        strokeWidth="1.5"
        d="M53.937 56.227a4.268 4.268 0 011.223-5.379l4.745-3.569a3.566 3.566 0 015.422 1.448 5.66 5.66 0 01-2.03 6.913l-3.178 2.153a4.268 4.268 0 01-6.182-1.566zM53.17 67.87a4.348 4.348 0 011.293-5.645l5.228-3.708a3.596 3.596 0 015.356 1.447 5.708 5.708 0 01-2.125 7.17l-3.603 2.302a4.348 4.348 0 01-6.15-1.566z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        stroke="#333"
        strokeWidth="1.5"
        d="M54.128 77.732A4.361 4.361 0 0155.38 72.1l3.451-2.514a3.573 3.573 0 015.34 1.374 5.672 5.672 0 01-2.064 7.172l-1.797 1.159a4.361 4.361 0 01-6.182-1.56z"
        clipRule="evenodd"
      />
    </svg>
  );
}

module.exports = OnboardingModalImage;
