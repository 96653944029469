const React = require('react');
const PropTypes = require('prop-types');
const Button = require('@andes/button');
const { useI18n } = require('nordic/i18n');
const IconCopy = require('./IconCopy');
const IconThumbDown = require('./IconThumbDown');
const { assistent } = require('../../../utils/search-client');
const { getEventTracker } = require('../../../../../../utils/event-tracker');

const { useState } = React;

function Feedback({ lang, id, siteId, answer }) {
  const { i18n } = useI18n();
  const Tracker = getEventTracker();
  const [feedbackGiven, setFeedbackGiven] = useState(false);

  /**
   * Pass true for positive or false for negative Feedback
   *
   * @param {boolean} positive
   */
  function handleFeedbackClick(positive) {
    Tracker.send(`mpdevelopers/search/feedback/${positive ? 'positive' : 'negative'}`, { id, siteId });
    const LLMSDK = assistent({ lang, site: siteId });
    LLMSDK.feedback(id, positive);
    setFeedbackGiven(true);
  }

  function copyToClipboard(content) {
    try {
      navigator.clipboard.writeText(content);
      Tracker.send('dx/search/ai-answer/copy');
    } catch (error) {
      console.error(error);
      Tracker.send('dx/search/ai-answer/copy/error');
    }
  }

  return (
    <div className="search-result-ai__feedback">
      <ul>
        <li>
          <Button
            size="small"
            srLabel="Copy"
            hierarchy="transparent"
            title={i18n.gettext('Copiar')}
            onClick={() => copyToClipboard(answer)}
          >
            <IconCopy />
          </Button>
        </li>

        <li>
          <Button
            size="small"
            srLabel="Positive"
            data-feedback-positive
            hierarchy="transparent"
            disabled={feedbackGiven}
            title={`Feedback: ${i18n.gettext('Sí')}`}
            onClick={() => handleFeedbackClick(true)}
          >
            <IconThumbDown />
          </Button>
        </li>

        <li>
          <Button
            size="small"
            srLabel="Negative"
            data-feedback-negative
            hierarchy="transparent"
            disabled={feedbackGiven}
            data-dylan-color="orange"
            title={`Feedback: ${i18n.gettext('No')}`}
            onClick={() => handleFeedbackClick(false)}
          >
            <IconThumbDown />
          </Button>
        </li>
      </ul>
    </div>
  );
}

Feedback.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
};

module.exports = Feedback;
