const React = require('react');

function OnlinePaymentImage() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 102 100"
    >
      <path
        fill="#fff"
        stroke="#fff"
        strokeWidth="3"
        d="M20.992 19.77h78.44v45.117a4.5 4.5 0 01-4.5 4.5h-69.44a4.5 4.5 0 01-4.5-4.5V19.77z"
      />
      <path
        stroke="#333"
        strokeWidth="1.5"
        d="M19.492 18.27h81.44v46.617a6 6 0 01-6 6h-69.44a6 6 0 01-6-6V18.27z"
      />
      <path
        fill="#fff"
        stroke="#333"
        strokeWidth="1.5"
        d="M19.492 16.406a3.175 3.175 0 013.175-3.174h75.105a3.175 3.175 0 013.175 3.174v2.033H19.492v-2.033z"
      />
      <ellipse
        cx="24.997"
        cy="15.983"
        stroke="#333"
        strokeWidth="1.5"
        rx="0.776"
        ry="0.776"
      />
      <ellipse
        cx="28.477"
        cy="15.983"
        stroke="#333"
        strokeWidth="1.5"
        rx="0.776"
        ry="0.776"
      />
      <ellipse
        cx="32.085"
        cy="15.983"
        stroke="#333"
        strokeWidth="1.5"
        rx="0.776"
        ry="0.776"
      />
      <path
        fill="#5AD2FF"
        d="M0 0H27.661V27.989H0z"
        transform="matrix(-1 0 0 1 88.09 29.608)"
      />
      <path
        stroke="#333"
        strokeWidth="1.5"
        d="M0 0H27.661V27.989H0z"
        transform="matrix(-1 0 0 1 88.09 30.152)"
      />
      <path
        stroke="#333"
        strokeWidth="1.5"
        d="M86.044 37.876V32.44h-3.98M82.064 54.29h3.98v-5.434M60.43 22v44.565M74.172 39.589c-.36-1.103-1.174-1.782-2.598-1.782-1.492 0-2.6 1.135-2.6 2.798 0 2.062.904 2.48 2.277 2.972l.259.096.17.065c1.106.425 2.492.836 2.614 2.491.122 1.655-.85 3.129-2.82 3.123-1.392-.004-2.568-.889-2.779-2.285M71.617 35.768v2.037M71.617 49.492v2.037"
      />
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M45.917 69.756V29.177c0-1.92-1.594-3.479-3.56-3.479H16.233c-1.966 0-3.56 1.558-3.56 3.48V81.508c0 2.27 1.883 4.11 4.206 4.11h24.833c2.323 0 4.206-1.84 4.206-4.11V69.756z"
        clipRule="evenodd"
      />
      <path
        fill="#fff"
        stroke="#fff"
        strokeWidth="3"
        d="M44.376 33.849v42.833H14.144V33.85h30.232z"
      />
      <path
        fill="#EEE"
        fillRule="evenodd"
        d="M41.525 83.697H16.85c-2.308 0-4.18-1.786-4.18-3.99v1.923c0 2.203 1.872 3.99 4.18 3.99h24.674c2.308 0 4.18-1.787 4.18-3.99v-1.922c0 2.203-1.872 3.989-4.18 3.989z"
        clipRule="evenodd"
        style={{ mixBlendMode: 'multiply' }}
      />
      <path
        stroke="#333"
        strokeWidth="1.5"
        d="M45.889 69.756V29.177c0-1.92-1.594-3.479-3.56-3.479H16.204c-1.966 0-3.56 1.558-3.56 3.48V81.508c0 2.27 1.883 4.11 4.206 4.11h24.833c2.323 0 4.206-1.84 4.206-4.11V69.756z"
        clipRule="evenodd"
      />
      <path
        stroke="#333"
        strokeWidth="1.5"
        d="M12.672 78.182h33.032M45.704 32.285H12.672M27.456 29.31h4.9M25.492 29.31h.884M27.163 81.582h4.262"
      />
      <path
        fill="#5AD2FF"
        d="M1.699 44.349H29.360000000000003V64.785H1.699z"
      />
      <path
        stroke="#333"
        strokeWidth="1.5"
        d="M1.699 44.746H29.360000000000003V65.182H1.699z"
      />
      <path
        stroke="#333"
        strokeWidth="1.5"
        d="M3.745 50.386v-3.969h3.98M7.725 62.371h-3.98v-3.968M29.36 38.794v32.54M23.503 51.56c-.36-.806-1.174-1.302-2.598-1.302-1.492 0-2.6.829-2.6 2.043 0 1.506.904 1.811 2.277 2.17l.259.07.17.048c1.106.31 2.492.61 2.614 1.819.122 1.208-.85 2.284-2.82 2.28-1.392-.003-2.568-.649-2.779-1.669M20.948 48.77v1.487M20.948 58.79v1.488"
      />
    </svg>
  );
}

module.exports = OnlinePaymentImage;
