/* eslint-disable max-len */
const React = require('react');

const ChevronRight = ({ className }) => (
  <svg className={className} width="20" height="18" viewBox="0 0 20 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.27735 3.34644L7.42882 4.19496L12.2245 8.99059L7.42383 13.7912L8.27236 14.6397L13.9215 8.99059L8.27735 3.34644Z" fill="#009EE3" />
  </svg>
);

module.exports = ChevronRight;
