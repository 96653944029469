/* eslint-disable react/prop-types */
const React = require('react');
const { Pill } = require('@andes/badge');

const CommunityHistory = ({ items, title, isMobile }) => (
  <div className="result-list-wrapper">
    {Boolean(title) && (
      <p className="result-box-header" style={{ marginTop: 0 }}>
        { title }
      </p>
    )}

    {items.map(() => {
      return (
        <div className="result-item">
          <a href="/">
            <div className="result-item__head">
              <h3>
                {/* Title */}

                {!isMobile && (
                  <Pill
                    size="small"
                    color="accent"
                    hierarchy="quiet"
                    roundedCorners={['top-right', 'top-left', 'bottom-right', 'bottom-left']}
                  >
                    <span style={{
                      height: '10px',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                      {/* Section */}
                    </span>
                  </Pill>
                )}
              </h3>
            </div>

            <div className="result-item__body">
              <p>
                {/* Except... */}
              </p>
            </div>
          </a>
        </div>
      );
    })}
  </div>
);

module.exports = CommunityHistory;
