/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
const React = require('react');
const { Link } = require('frontend-spa');
const injectI18n = require('nordic/i18n/injectI18n');
const urlParser = require('url');
const Collapse = require('../images/Collapse');
const DummyLink = require('../DummyLink');
const ApiRefPill = require('../ApiRefPill');

const { useRef } = React;

const SideBarItem = (props) => {
  const subMenu = useRef(null);
  const mainMenu = useRef(null);
  const collapseIcon = useRef(null);

  function verifyChildAtive(active, item) {
    if (!item.childs) {
      return false;
    }
    return item.childs.find((element) => {
      if (element?.key === active?.key) {
        return true;
      } if (element.childs) {
        return verifyChildAtive(active, element);
      }
      return false;
    });
  }

  const { item, active, i18n, lang, activeItem: currentActiveItem, setActiveItem, setShowMenu } = props;
  const { url, title, childs: itemChilds = [], key, isNew, type, api_method: apiMethod, divisor_above: divisorAbove } = item;
  const isApiRef = type === 'references';
  let { lvl } = item;
  lvl -= 1;
  const childs = itemChilds?.filter(child => !child.hidden) || [];
  const parsedUrl = urlParser.parse(active).pathname;
  const activeUrl = parsedUrl.endsWith('/') ? parsedUrl.slice(0, -1) : parsedUrl;
  const activeItem = (currentActiveItem && currentActiveItem.key === key);
  const isChildActive = verifyChildAtive(currentActiveItem, item);
  const childsOpen = childs && childs.length > 0;

  let LinkComponent = Link;
  let toEndpoint = url && url !== '#' ? `/developers/${lang}${url}` : `/developers/${lang}${activeUrl}#`;
  if (url && (url.includes('https://') || url.includes('http://') || url.match(/\/developers\/(es|pt|en)\/(support|reference|developer-program)/g))) {
    toEndpoint = url;
    LinkComponent = DummyLink;
  }

  if (toEndpoint.match(/\/developers\/(es|pt|en)\/live-demo/g)) {
    LinkComponent = DummyLink;
  }

  function changeCollapseSubMenu() {
    collapseIcon.current.classList.toggle('collapse-image--closed');
    subMenu.current.classList.toggle('active-submenu');
    subMenu.current.classList.toggle(`child-count-${childs.length}${isApiRef ? '-api' : ''}`);
  }

  function changeSubMenu() {
    const currentActiveItemKey = currentActiveItem ? currentActiveItem.key : null;

    if (url !== '' && url !== '#' && url !== activeUrl && !url?.includes('https://') && !url?.includes('http://')) {
      setShowMenu(false);
      setActiveItem(item);
    } else if (item.key === currentActiveItemKey && childs.length > 0) {
      changeCollapseSubMenu();
    } else if (item.key !== currentActiveItemKey && childs.length > 0 && url === '') {
      changeCollapseSubMenu();
    } else if (item.key !== currentActiveItemKey && url !== '') {
      setActiveItem(item);
    }
  }

  return (
    <div className={`lvl-${lvl} lvl-${lvl}${activeItem ? '--active' : ''}`} ref={mainMenu}>
      {divisorAbove
      && <div className="item-divisor subitem"><div className="divisor" /></div>}
      <LinkComponent
        hasChild={`${childsOpen}`}
        to={toEndpoint}
        className={`item-container ${activeItem && !isChildActive ? 'item-container--active' : ''} item-container-lvl-${lvl} ${url ? '' : 'subgroup'} ${isApiRef ? 'api-ref-container' : ''} ${divisorAbove ? 'title-divisor-above' : ''}`}
        onClick={() => changeSubMenu()}
        openOnSame={url && url.match(/\/developers\/(es|pt|en)\/(support|reference)/g)}
      >
        <div className="left-marker" />

        <p className={`title title-lvl-${lvl} ${url ? '' : 'subgroup-title'} ${isApiRef ? `api-ref-title-lvl-${lvl}` : ''} ${isChildActive ? 'bold' : ''} ${divisorAbove ? 'title-divisor-above' : ''}`}>
          {title}
        </p>

        {childs?.length > 0
        && (
          <div
            className={`collapse-image ${isChildActive ? 'collapse-image--collapsed' : ''}`}
            ref={collapseIcon}
          >
            <Collapse />
          </div>
        )}

        {isNew && (
          <div className="isnew-dot" />
        )}

        {apiMethod && (
          <ApiRefPill apiMethod={apiMethod} />
        )}

      </LinkComponent>
      {childs && childsOpen
       && (
       <div ref={subMenu} className={`childs ${isChildActive || activeItem ? `active-submenu child-count-${childs.length}${isApiRef ? '-api' : ''} ${lvl > 1 ? 'active-submenu-children' : ''}` : ''}`}>
         {childs.map(child => (
           <SideBarItem
             key={child.key}
             active={active}
             item={child}
             i18n={i18n}
             lang={lang}
             activeItem={currentActiveItem}
             setActiveItem={setActiveItem}
             setShowMenu={setShowMenu}
           />
         ))}
       </div>
       )}
    </div>
  );
};

module.exports = injectI18n(SideBarItem);
