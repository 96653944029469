/* eslint-disable react/jsx-no-target-blank */
const React = require('react');
const { getEventTracker } = require('../../../../../utils/event-tracker');

const HomeHelpCard = ({ image, title, description, link, linkDescription, linkIcon, isRel, tracking }) => {
  const Tracker = getEventTracker();

  return (
    <div className="home-help__card">
      {image}
      <h5>{title}</h5>
      <p>{description}</p>
      {isRel === true
      && <a href={link} rel="noreferrer" target="_blank" onClick={() => { Tracker.send(`mpdevelopers/click_${tracking}`); }}>{linkDescription}{linkIcon} </a>}
      {isRel === false
      && <a href={link} target="_blank" onClick={() => { Tracker.send(`mpdevelopers/click_${tracking}`); }}>{linkDescription}{linkIcon} </a>}
    </div>
  );
};

module.exports = HomeHelpCard;
