/* eslint-disable max-len */
const React = require('react');

const CertificationPurpleImage = () => (
  <svg width="49" height="36" viewBox="0 0 49 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="6" y="-0.000976562" width="37" height="36" rx="9" fill="#AB86FF" />
    <rect x="6" y="-0.000976562" width="37" height="36" rx="9" fill="black" fillOpacity="0.3" />
    <rect x="6" y="-0.000976562" width="37" height="33" rx="9" fill="#AB86FF" />
    <rect x="6.75" y="0.749023" width="35.5" height="34.5" rx="8.25" stroke="#333333" strokeWidth="1.5" />
    <path d="M2.37667 15.8968C1.16944 11.0679 4.82172 6.39014 9.79925 6.39014H39.2007C44.1783 6.39014 47.8306 11.0679 46.6233 15.8968C46.3187 17.1152 46.3187 18.3898 46.6233 19.6081C47.8306 24.437 44.1783 29.1148 39.2007 29.1148H9.79925C4.82172 29.1148 1.16944 24.437 2.37667 19.6081C2.68126 18.3898 2.68126 17.1152 2.37667 15.8968Z" fill="white" />
    <path d="M2.59141 13.6635C1.76303 10.3499 4.26916 7.14014 7.68466 7.14014H41.3153C44.7308 7.14014 47.237 10.3499 46.4086 13.6634L45.7956 16.1153C45.5269 17.1902 45.5269 18.3147 45.7956 19.3896L46.4086 21.8415C47.237 25.155 44.7308 28.3648 41.3153 28.3648H7.68466C4.26916 28.3648 1.76303 25.155 2.59141 21.8415L3.20438 19.3896C3.4731 18.3147 3.4731 17.1902 3.20438 16.1153L2.59141 13.6635Z" stroke="#333333" strokeWidth="1.5" />
    <rect x="9" y="14.999" width="31" height="6" rx="3" fill="#785EB3" />
  </svg>
);

module.exports = CertificationPurpleImage;
